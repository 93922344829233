import React from 'react'
import Wrapper from './containers/Wrapper'
import awsconfig from './aws-exports'
import {
  withAuthenticator,
  SignIn,
  ConfirmSignIn,
  VerifyContact,
  ForgotPassword,
  TOTPSetup,
  ConfirmSignUp,
  RequireNewPassword
} from 'aws-amplify-react'
import Amplify from 'aws-amplify'
import Routes from "./routes";

Amplify.configure(awsconfig)

function App() {
  return <Routes />
}

export default withAuthenticator(App, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ForgotPassword />,
  <TOTPSetup />,
  <RequireNewPassword />,
  <ConfirmSignUp />
])
