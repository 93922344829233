
import React from 'react';
import styles from '../../routes/summary/Summary.module.scss';
import classNames from 'classnames';
import StylEZ from '../../utils/StylEZ';
import moment from 'moment';

const stylez = new StylEZ(styles)

const CaptionWithDate = (props) => (
    <p
    className={classNames(
      stylez.hasTextCentered,
      stylez.subtitle,
      stylez.is4
    )}
  >
  {/* <button className={classNames(stylez.button, stylez.isInfo, stylez.isOutlined, stylez.isSmall)} 
    onClick={() => window.location.reload}
  >Refresh</button> */}
  <div className={classNames(stylez.columns)}>
    <div className={classNames(stylez.column, stylez.isThreeFifths)}>
      
    </div>
    <div className={classNames(stylez.column)}>
      <span className={classNames(stylez.subtitle, stylez.is7)}>
        {"last updated at " + moment((props.summary?.date)).format("MMMM Do YYYY, h:mm:ss a") ?? "-"}</span>
    </div>
  </div>
  </p>
)
export default CaptionWithDate;