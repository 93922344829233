import React, {useEffect, useState} from 'react';
import {Table, Divider, Space, Input, Button, Row, Select} from "antd";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash'

import notificationStyles from './notification.module.css'
import { getNotificationMessages } from "../../utils/notifications";
import {Link} from "react-router-dom";
const Notifications = (props) => {

    const [stage, setStage] = React.useState(localStorage.getItem('stage') || "dev")
    const [loading, setLoading] = React.useState(false);

    const [ notificationMessages, setNotificationMessages ] = useState([]);
    const [ notificatonMessagesTable, setNotificationMessagesTable ] = useState([]);
    const [ searchText, setSearchText] = useState(null);

    const onSearch = e => {
        setSearchText(e.target.value);
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'screenCode',
            dataIndex: 'screenCode',
            key: 'screenCode',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Body',
            dataIndex: 'body',
            key: 'body',
        },
        {
            title: 'updateUser',
            dataIndex: 'updateUser',
            key: 'updateUser',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => {
                return (
                    <Space size="middle">
                        <Link to={`/notifications/${record.id}/edit`}>Edit</Link>
                        <Link to={`/notifications/${record.id}`}>Send</Link>
                    </Space>
                );
            }
        },
    ]

    useEffect(() => {
        getAndMergeNotifications(stage)
    }, [stage])

    const getAndMergeNotifications = (stage) => {
        setLoading(true)
        getNotificationMessages(stage).then(response => {
            const groupedNotifications = _.groupBy(response, 'id');
            const notificationTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.language === 'tr')
                if(turkishNotification) {
                    let showedNotification = {
                        id: id,
                        language: turkishNotification.language,
                        title: turkishNotification.title,
                        body: turkishNotification.body,
                        screenCode: turkishNotification.screenCode,
                        updateUser: turkishNotification.updateUser,
                    }
                    notificationTable.push(showedNotification)
                }
            });
            setNotificationMessages(response);
            localStorage.setItem('notificationMessages', JSON.stringify(response));
            localStorage.setItem('stage', stage);
            setNotificationMessagesTable(notificationTable)
            setLoading(false)
        })
    }
    return (
        <>
            <Divider>Notifications</Divider>
            <Row justify="space-between" align="middle" style={{marginBottom: '1rem'}}>
                <Select defaultValue={stage} style={{ width: 120 }} onChange={(val) => setStage(val)}>
                    <Select.Option value="dev">Test</Select.Option>
                    <Select.Option value="prod">Prod</Select.Option>
                </Select>
                <Link to={`/notifications/create`} >
                    <Button type="primary">Create</Button>
                </Link>
            </Row>
            <Input.Search
                placeholder="Search with id"
                allowClear
                size="large"
                enterButton
                onChange={e => onSearch(e)}
            />
            <Table loading={loading} dataSource={searchText ? notificatonMessagesTable.filter(x => x.id.includes(searchText)).reverse() : notificatonMessagesTable.reverse()} columns={columns} />
        </>
    );
}

export default Notifications;