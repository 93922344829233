import { API, Auth } from 'aws-amplify'

const postSendEmail = async (sub,isbankCusNum,name,surname,email) => {

    return API.post('dashboard', '/dashboard/users/' + sub + "/post-send-email", {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
            phoneNumber : sub,
            isbankCusNum : isbankCusNum,
            name: name,
            surname: surname,
            email: email
        }
    })
}
export default postSendEmail