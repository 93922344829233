import { API, Auth } from 'aws-amplify'

const getUserLimitAmount = async (sub) => {

    return API.get('newDashboard', '/limit/' + sub, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

export default getUserLimitAmount;
