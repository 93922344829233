const colLimit = {
    columns:[{
        title: 'Limitin Açılacagı Tarih',
        dataIndex: 'newDate',
        key: 'newDate',
        sorter: (a, b) => a.date - b.date,
        sortDirections: ['descend', 'ascend']
    },
        {
            title:"isbankStatus",
            dataIndex:"isbankStatus",
            key:"isbankStatus"
        },
        {
            title:"Tutar",
            dataIndex:"amountMaccCurry",
            key:"amountMaccCurry",
            sorter: (a, b) => a.amountMaccCurry - b.amountMaccCurry,
            sortDirections: ['descend', 'ascend']
        },
    ]
}


export default colLimit;