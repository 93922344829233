import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { CSVLink } from "react-csv";
import json2csv from "json2csv";

import {DownloadOutlined, SaveOutlined, ArrowLeftOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {
    Switch,
    Button,
    Divider,
    Row,
    Typography,
    Form,
    Input,
    Col,
    notification as antNotification,
    Table,
    Space, Modal
} from "antd";

import notificationStyles from './campaign.module.css'

import {getPinpointCampaignType, updatePinpointCampaign, getCampaignUsage,getCampaignUsagePrice} from "../../utils/campaign";
import _ from "lodash";
import moment from "moment";

const EditNotification = () => {
    const { id } = useParams();
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const history = useHistory()
    const [ notifications, setNotifications ] = useState([])
    const [campaignUsageList, setCampaignUsageList ] = useState([])
    const [campaignUsageTable, setCampaignUsageTable] = useState([]);
    const [campaignDetailPrice, setCampaignDetailPrice] = useState([]);
    const [stage, setStage] = useState(localStorage.getItem('stage'))
    const [campaignStatus, setCampaignStatus] = useState(false)
    const [postStatus, setPostStatus] = useState([])
    const [loading, setLoading] = useState(false);
    const notificationMessages = localStorage.getItem('campaignList');
    const [formButtonLoadingVisible, setFormButtonLoadingVisible] = useState(false);
    const [ searchText, setSearchText] = useState(null);
    const showingNotifications = JSON.parse(notificationMessages).filter(x => x.id == id);
    var earningType = showingNotifications[0].earningType;
    var columns;
    const [ data, setData ] = useState([])
    const [ headers, setHeaders ] = useState([])
    const [ sumUser, setSumUser ] = useState(0)
    const [ sumAmount, setSumAmount ] = useState(0)
    const [ sumRemainingAmount, setSumRemainingAmount ] = useState(0)

    const onSearch = e => {
        setSearchText(e.target.value);
    }

    if (earningType === "voucher") {
        columns = [
            {
                title: 'User Id ',
                dataIndex: 'CognitoUsername',
                key: 'CognitoUsername',
                width: 200,
            },
            {
                title: 'Oluşturulma Tarihi',
                dataIndex: 'CreationTime',
                key: 'CreationTime',
                width: 200,
            },
            {
                title: 'Toplam Kazanım',
                dataIndex: 'Amount',
                key: 'Amount',
                width: 200,
            },
            {
                title: 'Kalan Kazanım',
                dataIndex: 'RemainingAmount',
                key: 'RemainingAmount',
                width: 200,
            },
        ]
    } else {
         columns = [
            {
                title: 'User Id ',
                dataIndex: 'userId',
                key: 'userId',
                width: 200,
            },
            {
                title: 'Oluşturulma Tarihi',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 200,
            },
            {
                title: 'Action',
                key: 'action',
                width: 200,
                render: (record) => {
                    // if record varsa yoksa return
                    return (
                        <>
                            {
                                record.campaignId !== "" && (
                                    <>
                                        <Form
                                            name="control-hooks"
                                            onFinish={() => onFinishDetail(record)}
                                        >
                                            <Form.Item>
                                                <Button
                                                    loading={formButtonLoadingVisible}
                                                    //onClick={}
                                                    type="info"
                                                    htmlType="submit">
                                                    Detay
                                                </Button>
                                            </Form.Item>
                                        </Form>

                                    </>
                                )
                            }

                        </>
                    );
                }
            },
        ]
    }



    useEffect(() => {
        getCampaignUsages();
        getStatus();
    }, []);

    const onFinishDetail = async values => {
        console.log("values.detail.toString()" + JSON.stringify(values.detail))

        await getCampaignUsagesPrice(values)

    };

    const handleCancel = () => {
        //console.log('Clicked cancel button');
        setFormButtonLoadingVisible(false);
    };

    const getStatus = () => {


        if(!notificationMessages) {
            history.push('/campaign')
        }

        console.log('showingNotifications:', showingNotifications[0].status );
        console.log('earningType:', earningType );
        if(showingNotifications[0].status == 'A') {
            setCampaignStatus(true);
        } else
            setCampaignStatus(false);


        if(showingNotifications.length === 0) {
            history.push('/campaign')
        }
        setNotifications(showingNotifications)
    }

    const onFinish = (values) => {
        console.log('Success:', JSON.stringify(values));
        setLoading(true)
        notifications.map(async (notification) => {

            const body = {
                "stage": stage ? stage : "dev",
                "id": notification.id,
                "status": postStatus
            }

            await updatePinpointCampaign(body)
        })



        setTimeout(() => {
            antNotification.success({
                message: 'Success',
            });
            setLoading(false);
            history.push('/campaign')

        }, 1500)

    };

    function onChange(checked) {
        console.log(`switch to ${checked}`);
        checked ?  setPostStatus("A") : setPostStatus("P")
    }

    const getCampaignUsages = () => {
        setLoading(true)
        getCampaignUsage(stage,earningType,id).then(response => {
            if (earningType ==="voucher") {
                const groupedNotifications = _.groupBy(response, 'Id');
                const localCampaignTable = [];
                var filteredCampaignTable = [];
                Object.keys(groupedNotifications).map(id => {
                    const turkishNotification = groupedNotifications[id].find(item => item.Id !== '')
                    if (turkishNotification) {
                        let showedCampaign = {
                            key:id,
                            Id: id,
                            CampaignId: turkishNotification.CampaignId,
                            CreationTime: moment(turkishNotification.CreationTime).format("DD-MM-YYYY"),
                            CognitoUsername: turkishNotification.CognitoUsername,
                            RemainingAmount: turkishNotification.RemainingAmount,
                            Amount: turkishNotification.Amount,
                            createdDate: turkishNotification.CreationTime,
                        }
                        localCampaignTable.push(showedCampaign)
                    }
                });
                setCampaignUsageList(response);
                setCampaignUsageTable(localCampaignTable)
                updateCSVData(localCampaignTable);
                setLoading(false)
                console.log('voucherCampaignUsageTable:', localCampaignTable);
                setSumUser(localCampaignTable.length);
                var localSumAmount = 0;
                var localSumRemainingAmount = 0;
                for (let i = 0; i < localCampaignTable.length; i++) {
                    localSumAmount += localCampaignTable[i].Amount;
                    localSumRemainingAmount += localCampaignTable[i].Amount - localCampaignTable[i].RemainingAmount;
                }
                setSumAmount(localSumAmount);
                setSumRemainingAmount(localSumRemainingAmount);
            } else {
                const groupedNotifications = _.groupBy(response, 'id');
                const localCampaignTable = [];
                var filteredCampaignTable = [];
                Object.keys(groupedNotifications).map(id => {
                    const turkishNotification = groupedNotifications[id].find(item => item.userId !== '')
                    if (turkishNotification) {
                        let showedCampaign = {
                            key:id,
                            id: id,
                            campaignId: turkishNotification.campaignId,
                            createdAt: moment(turkishNotification.createdAt).format("DD-MM-YYYY"),
                            userId: turkishNotification.userId,
                            paymentRef: turkishNotification.paymentRef,
                            transactionDate: moment(turkishNotification.transactionDate).format("DD-MM-YYYY"),
                            transactionId: turkishNotification.transactionId,
                            createdDate: turkishNotification.createdAt,
                        }
                        localCampaignTable.push(showedCampaign)
                    }
                });
                setCampaignUsageList(response);
                localStorage.setItem('getPinpointCampaignType', JSON.stringify(response));
                filteredCampaignTable = localCampaignTable.filter(t=>t.campaignId ==  id);
                setCampaignUsageTable(filteredCampaignTable);
                setSumUser(filteredCampaignTable.length);
                updateCSVData(localCampaignTable);
                setLoading(false)
                console.log('campaignUsageTable:', filteredCampaignTable);
            }

        })
    }

    const updateCSVData = (table= []) => {
        setHeaders([
            { label: 'CognitoUsername', key: 'CognitoUsername' },
            { label: 'CreationTime', key: 'CreationTime' },
            { label: 'Amount', key: 'Amount' },
            { label: 'RemainingAmount', key: 'RemainingAmount' }
        ])
        let tempData = [];
        console.log("campaignUsageList",table)
        tempData = table.map(item => ({
            CognitoUsername: item.CognitoUsername,
            CreationTime: item.CreationTime,
            Amount: item.Amount,
            RemainingAmount: item.RemainingAmount
        }))

        console.log("tempData",tempData)
        setData(tempData);
        console.log("data",data)
    }

    const getCampaignUsagesPrice = (values) => {
        setLoading(true)
        var sumAmount = 0;
        var sumRemainingAmount = 0;
        getCampaignUsagePrice(stage,values.userId,values.campaignId).then(response => {
            const groupedNotifications = _.groupBy(response, 'id');
            const localCampaignTable = [];
            var filteredCampaignTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.Id !== '')
                if (turkishNotification) {
                    let showedCampaign = {
                        key:id,
                        id: id,
                        Priority: turkishNotification.Priority,
                        StartDate: moment(turkishNotification.StartDate).format("DD-MM-YYYY"),
                        CognitoUsername: turkishNotification.CognitoUsername,
                        Amount: turkishNotification.Amount,
                        RemainingAmount: turkishNotification.RemainingAmount,
                        CampaignId: turkishNotification.CampaignId,
                        StartDateEpoch: turkishNotification.StartDate,
                    }
                    localCampaignTable.push(showedCampaign)
                }
            });

            for (var i = 0; i < localCampaignTable.length; i++) {
                sumAmount += localCampaignTable[i].Amount;
                sumRemainingAmount += localCampaignTable[i].RemainingAmount;
                console.log("sumAmount",sumAmount)
            }

            setLoading(false)

            Modal.info({
                destroyOnClose: true,
                title: "Kampanya Kullanım Miktarı",
                icon: <ExclamationCircleOutlined/>,
                content: (
                    <div>
                        <p>Toplam kazanım: {sumAmount}</p>
                        <p>Kalan kazanım : {sumRemainingAmount}</p>
                    </div>
                ),
                okText: 'Tamam',
                confirmLoading: formButtonLoadingVisible,
                onOk: handleCancel,
            });
        })
    }

    return (
        <>
            <Row justify="center">
                <Col span="16">
                    <Row align="middle">
                        <Col span="4">
                            <Button onClick={() => history.goBack()} className={notificationStyles.back__icon}  icon={<ArrowLeftOutlined />} size="large">
                                Back
                            </Button>
                        </Col>
                        <Col span="12">
                            <Typography.Paragraph  style={{marginBottom: 0}}>You're editing the campaign with <b> id: {id}</b> | env: <b>{stage}</b></Typography.Paragraph>
                        </Col>
                    </Row>
                    <Form {...layout} onFinish={onFinish}>
                        {
                            notifications.map(notification => (
                                <>
                                    <Divider orientation="left"><b></b></Divider>

                                    <Form.Item label="Status">
                                        <Switch defaultChecked={
                                            notification.status == 'A' ? true : false
                                        } onChange={onChange} />
                                    </Form.Item>

                                </>
                            ))
                        }
                        <Form.Item>
                            <Button loading={loading} htmlType="submit" className={notificationStyles.save__icon} type="primary" icon={<SaveOutlined />} size="large">
                                Kaydet
                            </Button>
                        </Form.Item>
                        <ul>
                            <li>Toplam Müşteri Sayısı: {sumUser}</li>
                            <li>Kazanılan Toplam İndirim: {sumAmount}</li>
                            <li>Harcanan Toplam İndirim: {sumRemainingAmount}</li>

                        </ul>

                        <Divider>Kullanan Müşteri Listesi</Divider>
                        <CSVLink
                            data={data}
                            headers={headers}
                            filename={"campaignDetail.csv"}
                            target="_blank"
                            style={{ textDecoration: 'none', outline: 'none', height: '5vh' }}
                        >
                            <Button variant="contained" color="secondary" style={{ height: '100%' }}>
                                Tabloyu İndir
                            </Button>
                        </CSVLink>
                        <Input.Search
                            placeholder="User Id ile arama"
                            allowClear
                            size="large"
                            enterButton
                            onChange={e => onSearch(e)}
                        />
                        <Table scroll={{x: 1200}} auto loading={loading}
                               dataSource={searchText ? campaignUsageTable.filter(x => earningType === "voucher" ? x.CognitoUsername.includes(searchText) : x.userId.includes(searchText)).reverse(): campaignUsageTable.sort(
                                   function(a, b) {
                                       return b.createdDate - a.createdDate
                                   })}
                               columns={columns}/>
                    </Form>
                </Col>
            </Row>
            <>

            </>
        </>
    )
}

export default EditNotification;