import React from 'react';
import styles from '../../routes/summary/Summary.module.scss';
import classNames from 'classnames'

const LabelTag = ({ label, condition }) => (
    <span
      //onClick={() => console.log(label)}
      className={classNames(
        styles['tag'],
        //styles['is-clickable'],
        {
          [styles['is-success']]: condition,
          [styles['is-danger']]: !condition
        },
        styles['is-light']
      )}
    >
      {label}
    </span>
  )

  export default LabelTag;