import { API, Auth } from 'aws-amplify';

const postTransferPhoneNumber = async (sub,oldPhoneNumber,newPhoneNumber) => {
    return API.post('newDashboard', '/customer/transferPhoneNumber', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
          oldPhoneNumber : "+" + sub,
          newPhoneNumber : newPhoneNumber
        } 
      })  
  }
  
  export default postTransferPhoneNumber;