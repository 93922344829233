import { API, Auth } from 'aws-amplify'

const postCancelAndTriggerTransaction = async (sub,command,paymentRef) => {

    return API.post('dashboard', '/dashboard/users/' + sub + "/post-cancel-trigger-transaction", {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
            command : command,
            paymentRef: paymentRef

        }
    })
}

export default postCancelAndTriggerTransaction