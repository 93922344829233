import { API, Auth } from 'aws-amplify'

const deleteUserInfo = async (sub) => {

    return API.post('newDashboard', '/customer/deletion',
    {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
            userId : sub
        }
    })
}

export default deleteUserInfo