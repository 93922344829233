import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector} from 'redux-form';
import {
    getPinpointCampaignType,
    getCampaignPinpoint,
    postCampaignPinpoint,
    updatePinpointCampaign
} from "../../utils/campaign";

import _ from "lodash";
import {
    Table,
    Divider,
    Space,
    Input,
    Button,
    Row,
    Select,
    DatePicker,
    InputNumber,
    notification as antNotification
} from "antd";
import Text from "antd/es/typography/Text";
import {
    createOrUpdateNotificationMessages,
    sendNotification as sendNotificationRequest
} from "../../utils/notifications";
import {Link, useHistory} from "react-router-dom";
import "./campaign.module.css";
import postGift from "../../utils/postGift";

const {RangePicker} = DatePicker;
const {Option} = Select;
let SelectingFormValuesForm = props => {

    const {
        event,
        campaignType,
        pinpointCampaign,
        favoriteColorValue,
        fullName,
        handleSubmit,
        hasEmailValue,
        hasEmailValue2,
        pristine,
        reset,
        submitting,
    } = props;

    const history = useHistory()

    const [ isSending, setIsSending ] = useState(false);
    const [stage, setStage] = React.useState(localStorage.getItem('stage') || "dev")
    const [loading, setLoading] = useState(false);
    const [campaignTable, setCampaignTable] = useState([]);
    const [basePinpointCampaignTable, setBasePinpointCampaignTable] = useState([]);
    const [pinpointCampaignTable, setPinpointCampaignTable] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const [pinpointCampaignList, setPinpointCampaignList] = useState([]);
    const [campaignTypeId, setCampaignTypeId] = useState([]);
    const [campaignId, setCampaignId] = useState([]);
    const [campaignName, setCampaignName] = useState([]);
    const [campaignStartDate, setCampaignStartDate] = useState(0);
    const [campaignEndDate, setCampaignEndDate] = useState(0);
    const [campaignEvent, setCampaignEvent] = useState([]);
    const [triggerType, setTriggerType] = useState([]);
    const [triggerMetric, setTriggerMetric] = useState(0);
    const [earningType, setEarningType] = useState([]);
    const [earningMessage, setEarningMessage] = useState([]);
    const [earningMetric, setEarningMetric] = useState(0);
    const [earningSource, setEarningSource] = useState([]);
    const [usageCount, setUsageCount] = useState(0);
    const [usageDateType, setUsageDateType] = useState([]);

    const [usageStartDate, setUsageStartDate] = useState(0);
    const [usageEndDate, setUsageEndDate] = useState(0);

    const [usageDayCount, setUsageDayCount] = useState(0);

    const [usagePriority, setUsagePriority] = useState(0);

    const [notifEarnTime, setNotifEarnTime] = useState(false);
    const [mailEarnTime, setMailEarnTime] = useState(false);
    const [notifUsageTime, setNotifUsageTime] = useState(false);
    const [mailUsageTime, setMailUsageTime] = useState(false);

    const [notifEarnId, setNotifEarnId] = useState(0);
    const [mailEarnId, setMailEarnId] = useState(0);
    const [notifUsageId, setNotifUsageId] = useState(0);
    const [mailUsageId, setMailUsageId] = useState(0);


    const [campaignTypeShow, setCampaignTypeShow] = useState(false);
    const [pinpointCampaignShow, setPinpointCampaignShow] = useState(false);
    const [dateShow, setDateShow] = useState(false);
    const [triggerTypeShow, setTriggerTypeShow] = useState(false);
    const [triggerMetricShow, setTriggerMetricShow] = useState(false);
    const [earningTypeShow, setEarningTypeShow] = useState(false);
    const [earningMessageShow, setEarningMessageShow] = useState(false);
    const [earningSourceShow, setEarningSourceShow] = useState(false);
    const [earningMetricShow, setEarningMetricShow] = useState(false);
    const [usageCountShow, setUsageCountShow] = useState(false);
    const [usageDateTypeShow, setUsageDateTypeShow] = useState(false);
    const [usageDateOrDaySelectionShow, setUsageDateOrDaySelectionShow] = useState(false);
    const [dateShow2, setDateShow2] = useState(false);
    const [usageDayCountShow, setUsageDayCountShow] = useState(false);
    const [notifEarnTimeShow, setNotifEarnTimeShow] = useState(false);
    const [mailEarnTimeShow, setMailEarnTimeShow] = useState(false);
    const [notifUsageTimeShow, setNotifUsageTimeShow] = useState(false);
    const [mailUsageTimeShow, setMailUsageTimeShow] = useState(false);
    const [submitButtonShow, setSubmitButtonShow] = useState(false);

    useEffect(() => {
        getCampaignType()
        getPinpointCampaign()
    }, [])

    function onChange(date, dateString) {
        console.log(date, dateString);
    }

    function handleChangeCampaign(value) {

        console.log("value " + value);
        setCampaignTypeId(value);
        console.log("setCampaignTypeId " + campaignTypeId);

        setCampaignEvent(campaignTable.filter(data => data.id === value)[0].event);
        var campaignType = campaignTable.filter(data => data.id === value)[0].event
        //console.log(`pinpointCampaignTable ` + pinpointCampaignTable.map(data => data.ApplicationId));
        console.log("campaignType",campaignType);
        setPinpointCampaignTable(basePinpointCampaignTable.filter(a => a.Schedule.EventFilter.Dimensions.EventType.Values[0] == campaignType))
        console.log(`selected CampaignEvent ` + campaignEvent);

        setPinpointCampaignShow(true);
        setDateShow(false);
        setTriggerTypeShow(false);
        setTriggerMetricShow(false);
        setEarningTypeShow(false);
        setEarningMessageShow(false);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function handleChangPinpointCampaign(value) {
        console.log(value);
        setCampaignId(value);
        console.log("CampaignId" + campaignId);
        setCampaignName(pinpointCampaignTable.filter(data => data.Id === value)[0].Name);
        console.log("CampaignName" + campaignName);

        setDateShow(true);
        setTriggerTypeShow(false);
        setTriggerMetricShow(false);
        setEarningTypeShow(false);
        setEarningMessageShow(false);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }


    function onChangeDate(date, dateString) {
        console.log(date, dateString);
        setCampaignStartDate(new Date(dateString[0]).valueOf());
        console.log(new Date(dateString[0]).valueOf());
        setCampaignEndDate(new Date(dateString[1]).valueOf());


        setTriggerTypeShow(true);
        setTriggerMetricShow(false);
        setEarningTypeShow(false);
        setEarningMessageShow(false);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function handleChangeCampaignGoal(value) {
        console.log(`selected TriggerType ` + value);
        setTriggerType(value);
        console.log(`selected TriggerType ` + triggerType);
        setTriggerMetricShow(true);
        setEarningTypeShow(false);
        setEarningMessageShow(false);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeNumber(value) {
        console.log(value);
        setTriggerMetric(value);

        setEarningTypeShow(true);
        setEarningMessageShow(false);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function handleChangeEarningType(value) {
        console.log(`handleChangeEarningType` + value);
        setEarningType(value);

        setEarningMessageShow(true);
        setEarningSourceShow(false);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function handleChangeEarningMessage(value) {
        console.log(`handleChangeEarningType` + value);
        setEarningMessage(value);

        setEarningSourceShow(true);
        setEarningMetricShow(false);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function handleChangeEarningSource(value) {
        console.log(`handleChangeEarningSource ` + value);
        setEarningSource(value);

        setEarningMetricShow(true);
        setUsageCountShow(false);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeEarnMetric(value) {
        console.log(value);
        setEarningMetric(value);

        setUsageCountShow(true);
        setUsageDateTypeShow(false);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeUsageCount(value) {
        console.log(value);
        setUsageCount(value);

        setUsageDateTypeShow(true);
        setUsageDateOrDaySelectionShow(false);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeUsageDateType(value) {
        setUsageDateType(value);
        console.log(value);

        setUsageDateOrDaySelectionShow(true);
        setDateShow2(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeUsageDayCount(value) {
        console.log(value);
        setUsageDayCount(value);

        setUsageDayCountShow(true);
        setDateShow2(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeDate2(date, dateString) {
        console.log(date, dateString);

        setUsageStartDate(new Date(dateString[0]).valueOf());
        console.log(new Date(dateString[0]).valueOf());
        setUsageEndDate(new Date(dateString[1]).valueOf());

        setDateShow2(true);
        setUsageDayCountShow(false);
        setUsageDayCountShow(false);
        setNotifEarnTimeShow(false);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeUsagePriority(value) {
        console.log(value);
        setUsagePriority(value);

        setNotifEarnTimeShow(true);
        setMailEarnTimeShow(false);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeNotifEarnTime(value) {
        var result = (value == "false") != Boolean(value);
        setNotifEarnTime(result);
        setMailEarnTimeShow(true);
        console.log(value);
    }

    function onChangeNotifEarnId(value) {
        console.log(value);
        setNotifEarnId(value)
        setMailEarnTimeShow(true);
        setNotifUsageTimeShow(false);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeMailEarnTime(value) {
        var result = (value == "false") != Boolean(value);
        setMailEarnTime(result);
        setNotifUsageTimeShow(true)
        console.log(value);
    }

    function onChangeMailEarnTemplateId(value) {
        console.log(value);
        setMailEarnId(value);
        setNotifUsageTimeShow(true);
        setMailUsageTimeShow(false);
        setSubmitButtonShow(false);
    }

    function onChangeNotifUsageTime(value) {
        var result = (value == "false") != Boolean(value);
        setNotifUsageTime(result);
        setMailUsageTimeShow(true);
        setSubmitButtonShow(false);
        console.log(value);
    }

    function onChangeNotifUsageId(value) {
        console.log(value);
        setNotifUsageId(value)
        setMailUsageTimeShow(true);
    }

    function onChangeMailUsageTime(value) {
        var result = (value == "false") != Boolean(value);
        setMailUsageTime(result);
        setSubmitButtonShow(true);
        console.log(value);
    }

    function onChangeMailUsageTemplateId(value) {
        console.log(value);
        setMailUsageId(value)

        //show submitButton
    }


    const getCampaignType = () => {
        setLoading(true)
        getPinpointCampaignType(stage).then(response => {
            const groupedNotifications = _.groupBy(response, 'id');
            const campaignTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.event !== '')
                if (turkishNotification) {
                    let showedCampaign = {
                        key: id,
                        id: id,
                        description: turkishNotification.description,
                        earningUser: turkishNotification.earningUser,
                        event: turkishNotification.event,
                        type: turkishNotification.type,
                    }
                    campaignTable.push(showedCampaign)
                }
            });
            setCampaignList(response);
            localStorage.setItem('getPinpointCampaignType', JSON.stringify(response));
            setCampaignTable(campaignTable)
            console.log(campaignTable);
            setLoading(false)
        })
    }

    const getPinpointCampaign = () => {
        setLoading(true)
        getCampaignPinpoint(stage).then(response => {
            const groupedNotifications = _.groupBy(response, 'Id');
            const pinpointCampaignTable = [];
            Object.keys(groupedNotifications).map(Id => {
                const turkishNotification = groupedNotifications[Id].find(item => item.Id !== '')
                if (turkishNotification) {
                    let showedCampaign = {
                        key: Id,
                        Id: Id,
                        ApplicationId: turkishNotification.ApplicationId,
                        AdditionalTreatments: turkishNotification.AdditionalTreatments,
                        Arn: turkishNotification.Arn,
                        CreationDate: turkishNotification.CreationDate,
                        CustomDeliveryConfiguration: turkishNotification.CustomDeliveryConfiguration,
                        Description: turkishNotification.Description,
                        HoldoutPercent: turkishNotification.HoldoutPercent,
                        IsPaused: turkishNotification.IsPaused,
                        LastModifiedDate: turkishNotification.LastModifiedDate,
                        Limits: turkishNotification.Limits,
                        MessageConfiguration: turkishNotification.MessageConfiguration,
                        Name: turkishNotification.Name,
                        Schedule: turkishNotification.Schedule,
                        SegmentId: turkishNotification.SegmentId,
                        SegmentVersion: turkishNotification.SegmentVersion,
                        State: turkishNotification.State,
                        tags: turkishNotification.tags,
                        TemplateConfiguration: turkishNotification.TemplateConfiguration,
                        Version: turkishNotification.Version
                    }
                    pinpointCampaignTable.push(showedCampaign)
                }
            });
            setPinpointCampaignList(response);
            localStorage.setItem('getPinpointCampaignType', JSON.stringify(response));
            setPinpointCampaignTable(pinpointCampaignTable)
            setBasePinpointCampaignTable(pinpointCampaignTable);
            console.log(pinpointCampaignTable);
            setLoading(false)
        })
    }


    const handleSubmit2= async (e) => {
        setLoading(true);
        setIsSending(true);
        e.preventDefault();

        const itemsObject = {
            "campaignTypeId": campaignTypeId,
            "earningMetric": earningMetric,
            "earningSource": earningSource,
            "earningType": earningType,
            "earningMessage":earningMessage,
            "endDate": campaignEndDate,
            "id": campaignId,
            "mailEarnTime": mailEarnTime,
            "mailUsageTemplateId": mailUsageId,
            "mailUsageTime": mailUsageTime,
            "name": campaignName,
            "notifEarnTime": notifEarnTime,
            "notifUsageId": notifUsageId,
            "notifUsageTime": notifUsageTime,
            "startDate": campaignStartDate,
            "status": "A",
            "triggerMetric": triggerMetric,
            "triggerType": triggerType,
            "usageCount": usageCount,
            "usageDateType": usageDateType,
            "usageEndDate": usageEndDate,
            "usagePriority": usagePriority,
            "usageStartDate": usageStartDate
        }

        const body = {
            "stage": stage,
            "items": [
                itemsObject
            ]
        }

        console.log("before postCampaign")

        console.log(body)
        await postCampaignPinpoint(body)
            .then(response => {
                console.log(response)
                if(response.code === 1)
                {
                    setTimeout(() => {
                        antNotification.success({
                            message: 'Başarılı!',
                        });
                        setIsSending(false);
                        setLoading(false);
                        history.push('/campaign')

                    }, 1500)

                }
                else{
                    setTimeout(() => {
                        antNotification.error({
                            message: "Hata!, kampanya oluşturulamadı.",
                        });
                        setIsSending(false);
                        setLoading(false);
                    }, 1500)
                }
            }).catch(error => {
            alert(error);
        })
    }



    return (

        <form className="formLabel" onSubmit={handleSubmit2} >
            <Divider>Kampanya Oluştur</Divider>
            <Row justify="space-between" align="middle" style={{marginBottom: '1rem'}}>
                <Select defaultValue={stage} style={{ width: 120 }} onChange={(val) => setStage(val)}>
                    <Select.Option value="dev">Test</Select.Option>
                    <Select.Option value="prod">Prod</Select.Option>
                </Select>
            </Row>
            <Text style={{fontWeight: 'bold', fontSize: '18px'}}>
                HANGİ TİP KAMPANYA? </Text>
            <div>
                <br/><label>Kampanya Tipi</label> <br/>
                <Select
                    style={{minWidth: "30%", height: "32px"}}
                    name="campaignType"
                    options={campaignTable.map(t => ({value: t.id, label: t.description}))}
                    placeholder="None Selected"
                    onChange={handleChangeCampaign}
                />
            </div>
            {pinpointCampaignShow ?
                <div>
                    <br/><label>Pinpoint Kampanyası</label> <br/>
                    <Select
                        style={{minWidth: "30%", height: "32px"}}
                        name="pinpointCampaign"
                        options={pinpointCampaignTable.map(t => ({value: t.Id, label: t.Name}))}
                        placeholder="None Selected"
                        onChange={handleChangPinpointCampaign}
                    />
                </div> :
                <div>
                </div>
            }

            {dateShow ?
                <div>
                    <br/>
                    <Text style={{fontWeight: 'bold', fontSize: '18px'}}> NE ZAMAN? </Text> <br/>
                    <br/><label>Başlangıç ve Bitiş Tarihi</label> <br/>
                    <Space direction="vertical">
                        <RangePicker onChange={onChangeDate}/>
                    </Space>
                    <br/>
                </div> :

                <div>
                </div>}

            <div>
                {(() => {
                    if (triggerTypeShow) {
                        if (campaignEvent == "signup") {
                            return (
                                <div>
                                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> NASIL? </Text> <br/>
                                    <br/><label>Kampanya Hangi Değer Hedefiyle Gerçekleşsin ?</label> <br/>
                                    <Select name="campaignGoal" placeholder="None Selected"
                                            style={{minWidth: "30%", height: "32px"}}
                                            onChange={handleChangeCampaignGoal}>
                                        <Option value="count"> İşlem Sayısı</Option>
                                        <Option value="referralCode"> Kampanya kodu ile signup olursa</Option>
                                    </Select>
                                </div>

                            )
                        } else {
                            return (
                                <div>
                                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> NASIL? </Text> <br/>
                                    <br/> <label>Kampanya Hangi Değer Hedefiyle Gerçekleşsin ?</label> <br/>
                                    <Select name="campaignGoal" placeholder="None Selected"
                                            style={{minWidth: "30%", height: "32px"}}
                                            onChange={handleChangeCampaignGoal}>
                                        <Option value="amount">Tutar Hedefi</Option>
                                        <Option value="commissionAmount">Komisyon Hedefi</Option>
                                        <Option value="count"> İşlem Sayısı</Option>
                                    </Select>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }
                })()}
            </div>

            <div>
                {(() => {
                    if (triggerMetricShow) {
                        if (campaignEvent == "transaction" && triggerType == "amount") {
                            return (

                                <div>
                                    <br/> <label>Hedeflenen Tutarı Giriniz ?</label> <br/>
                                    <InputNumber min={0} defaultValue={0} formatter={value => `${value} €`} onChange={onChangeNumber}/>
                                </div>

                            )
                        } else if (campaignEvent == "transaction" && triggerType == "commissionAmount") {
                            return (
                                <div>
                                    <br/> <label>Hedeflenen Komisyon Tutarını Giriniz ?</label> <br/>
                                    <InputNumber min={0} defaultValue={0} formatter={value => `${value} €`} onChange={onChangeNumber}/>
                                </div>
                            )
                        } else if (campaignEvent == "transaction" && triggerType == "count") {
                            return (
                                <div>
                                    <br/> <label>Hedeflenen İşlem Sayısını Giriniz ?</label> <br/>
                                    <InputNumber min={0} defaultValue={0} formatter={value => `${value} adet`} onChange={onChangeNumber}/>
                                </div>
                            )
                        } else if (campaignEvent == "signup" && triggerType == "count") {
                            return (
                                <div>
                                    <br/> <label>Hedeflenen Signup Sayısını Giriniz ?</label> <br/>
                                    <InputNumber min={0} defaultValue={0} formatter={value => `${value} adet`} onChange={onChangeNumber}/>
                                </div>
                            )
                        } else if (campaignEvent == "signup" && triggerType == "referralCode") {
                            return (
                                <div>
                                    <br/> <label>Hedeflenen Kampanya Kodunu Giriniz ?</label> <br/>
                                    <Input style={{ width: '30%' }} formatter={value => `${value}`} onChange={onChangeNumber}/>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }
                })()}
            </div>

            {earningTypeShow ?
                <div>
                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> NE KAZANACAK? </Text> <br/>
                    <br/><label> Kazanımı Ne Olsun?</label> <br/>
                    <Select name="voucher" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={handleChangeEarningType}>
                        <Option value="voucher">Voucher</Option>
                    </Select>
                </div> :
                <div>
                </div>}

            {earningMessageShow ?
                <div>
                    <br/><label> Tanımlanacak İndirimin App'de Gösterim mesajı nedir ?</label> <br/>
                    <Input style={{ width: '30%' }} formatter={value => `${value}`} onChange={handleChangeEarningMessage}/>
                </div> :
                <div>
                </div>}

            <div>
                {(() => {
                    if (earningSourceShow) {
                        if (campaignEvent == "transaction") {
                            return (
                                <div>
                                    <br/><label>Kazanımı Nasıl Hesaplansın?</label> <br/>
                                    <Select name="earningSource" placeholder="None Selected"
                                            style={{minWidth: "30%", height: "32px"}}
                                            onChange={handleChangeEarningSource}>
                                        <Option value="amount">İşlem Tutarı Üzerinden</Option>
                                        <Option value="commission">Komisyon Ücreti Üzerinden</Option>
                                        <Option value="static"> Sabit Tutar</Option>
                                    </Select>
                                </div>

                            )
                        } else if (campaignEvent == "signup") {
                            return (
                                <div>
                                    <br/><label>Kazanımı Nasıl Hesaplansın?</label> <br/>
                                    <Select name="earningSource" placeholder="None Selected"
                                            style={{minWidth: "30%", height: "32px"}}
                                            onChange={handleChangeEarningSource}>
                                        <Option value="static">Sabit Tutar</Option>
                                    </Select>
                                </div>

                            )
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }
                })()}
            </div>

            <div>
                {(() => {
                    if (earningMetricShow) {
                        if (earningSource == "amount") {
                            return (
                                <div>
                                    <br/><label>İşlem Tutarının Yüzde Kaçı Verilsin?</label> <br/>
                                    <InputNumber min={0} max={100} defaultValue={0} formatter={value => `% ${value}`} onChange={onChangeEarnMetric}/>
                                </div>

                            )
                        } else if (earningSource == "commission") {
                            return (
                                <div>
                                    <br/><label>Komisyon Tutarının Yüzde Kaçı Verilsin?</label> <br/>
                                    <InputNumber min={0} max={100} defaultValue={0} formatter={value => `% ${value}`} onChange={onChangeEarnMetric}/>
                                </div>
                            )
                        } else if (earningSource == "static") {
                            return (
                                <div>
                                    <br/><label>Kaç euro hediye verilsin</label> <br/>
                                    <InputNumber min={0} max={100} defaultValue={0} formatter={value => `${value} €`} onChange={onChangeEarnMetric}/>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }
                })()}
            </div>

            {usageCountShow ?
                <div>
                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> KAÇ KERE? </Text> <br/>
                    <br/><label>Kampanyadan kaç kere faydalanabilsin?</label> <br/>
                    <InputNumber min={0} defaultValue={0} onChange={onChangeUsageCount}/>
                </div>
                :

                <div>
                </div>}

            {usageDateTypeShow ?
                <div>
                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> KULLANIM ZAMANI? </Text> <br/>
                    <br/><label>Hangi Zaman Skalası ile Kullansın?</label> <br/>
                    <Select name="usageDateType" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={onChangeUsageDateType}>
                        <Option value="period">Sabit Başlangıç-Bitiş Arası</Option>
                        <Option value="day">Kazanım Sonrası X Gün İçinde</Option>
                    </Select>
                </div>
                :
                <div>
                </div>}
            <div>
                {(() => {
                    if (usageDateOrDaySelectionShow) {
                        if (usageDateType == "period") {
                            return (
                                <div>
                                    <br/><label>Başlangıç ve Bitiş Tarihi</label> <br/>
                                    <Space direction="vertical">
                                        <RangePicker onChange={onChangeDate2}/>
                                    </Space>
                                </div>
                            )
                        } else if (usageDateType == "day") {
                            return (
                                <div>
                                    <br/><label>Kazanım Sonrası Kaç Gün Boyunca Kullanabilsin?</label> <br/>
                                    <InputNumber min={0} max={100} defaultValue={0} onChange={onChangeUsageDayCount}/>
                                </div>
                            )
                        }
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }

                })()}
            </div>

            <div>
                {(() => {
                    if (dateShow2 || usageDayCountShow) {
                        return (
                            <div>
                                <br/><label>Kampanya Kazanım Kullanım Önceliği (0-10.000)</label> <br/>
                                <InputNumber min={0} max={10000} defaultValue={0} onChange={onChangeUsagePriority}/>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                            </div>
                        )
                    }

                })()}
            </div>

            {notifEarnTimeShow ?

                <div>
                    <br/> <Text style={{fontWeight: 'bold', fontSize: '18px'}}> BİLDİRİMLER </Text> <br/>
                    <br/><label>Kazanım Anında Bildirim Atılsın mı?</label> <br/>
                    <Select name="notifEarnTime" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={onChangeNotifEarnTime}>
                        <Option value="true">Evet</Option>
                        <Option value="false">Hayır</Option>
                    </Select>
                </div>
                :
                <div>
                </div>}

            <div>
                {notifEarnTime == true ?
                    <div>
                        <br/><label>Bildirim Id’sini Girin?</label> <br/>
                        <InputNumber min={0} defaultValue={0} onChange={onChangeNotifEarnId}/>
                    </div> :
                    <div>
                    </div>
                }
            </div>
            {mailEarnTimeShow ?
                <div>
                    <br/><label>Kazanım Anında Mail Atılsın mı?</label> <br/>
                    <Select name="mailEarnTime" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={onChangeMailEarnTime}>
                        <Option value="true">Evet</Option>
                        <Option value="false">Hayır</Option>
                    </Select>
                </div>
                :
                <div>
                </div>}
            <br/>
            <div>
                {mailEarnTime == true ?
                    <div>
                        <br/><label>Mail Template Id’sini Girin?</label> <br/>
                        <InputNumber min={0} defaultValue={0} onChange={onChangeMailEarnTemplateId}/>
                    </div> :
                    <div>
                    </div>
                }
            </div>

            {notifUsageTimeShow ?
                <div>
                    <br/><label>Kullanım Başlangıç Tarihinde Bildirim Atılsın mı?</label> <br/>
                    <Select name="notifUsageTime" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={onChangeNotifUsageTime}>
                        <Option value="true">Evet</Option>
                        <Option value="false">Hayır</Option>
                    </Select>
                </div>
                :
                <div>
                </div>
            }

            <div>
                {notifUsageTime == true ?
                    <div>
                        <br/><label>Bildirim Id’sini Girin?</label> <br/>
                        <InputNumber min={0} defaultValue={0} onChange={onChangeNotifUsageId}/>
                    </div> :
                    <div>
                    </div>
                }
            </div>

            {mailUsageTimeShow ?
                <div>
                    <br/><label>Kazanım Anında Mail Atılsın mı?</label> <br/>
                    <Select name="mailUsageTime" placeholder="None Selected"
                            style={{minWidth: "30%", height: "32px"}} onChange={onChangeMailUsageTime}>
                        <Option value="true">Evet</Option>
                        <Option value="false">Hayır</Option>
                    </Select>
                </div>
                :
                <div>
                </div>
            }
            <br/>
            <div>
                {mailUsageTime == true ?
                    <div>
                        <br/><label>Bildirim Id’sini Girin?</label> <br/>
                        <InputNumber min={0} defaultValue={0} onChange={onChangeMailUsageTemplateId}/>
                    </div> :
                    <div>
                    </div>
                }
            </div>
            <br/>
            {mailUsageTimeShow ?
                <div>
                    <br/>
                    <Button type="primary" htmlType="submit" loading={isSending}>
                        Tamamla
                    </Button>
                    <br/>
                </div>

            :
                <div>
                </div>
            }

        </form>

    );
};

// The order of the decoration does not matter.

// Decorate with redux-form
SelectingFormValuesForm = reduxForm({
    form: 'selectingFormValues', // a unique identifier for this form
})(SelectingFormValuesForm);

// Decorate with connect to read form values
const selector = formValueSelector('selectingFormValues'); // <-- same as form name
SelectingFormValuesForm = connect(state => {
    // can select values individually
    const hasEmailValue = selector(state, 'hasEmail');
    const hasEmailValue2 = selector(state, 'hasEmail2');
    const favoriteColorValue = selector(state, 'favoriteColor');
    const campaignType = selector(state, 'campaignType');
    const pinpointCampaign = selector(state, 'pinpointCampaign');
    const event = selector(state, 'event');


    // or together as a group
    const {firstName, lastName} = selector(state, 'firstName', 'lastName');
    return {
        hasEmailValue,
        hasEmailValue2,
        favoriteColorValue,
        campaignType,
        pinpointCampaign,
        event,
        fullName: `${firstName || ''} ${lastName || ''}`,
    };
})(SelectingFormValuesForm);

export default SelectingFormValuesForm;
