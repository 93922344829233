import { API, Auth } from 'aws-amplify'

const getWithPagination = async (paginationToken = undefined, searchedUser = undefined, timeframe = undefined, searchType = undefined) => {
  var queryStringVariables = {};
  if (paginationToken) queryStringVariables.paginationToken = paginationToken;
  if (searchType) queryStringVariables.searchType = searchType;

  if(searchType && searchType === 'n')
  {
    queryStringVariables.searchedName = searchedUser.split("~")[0];
    queryStringVariables.searchedSurname = searchedUser.split("~")[1];
  }
  if(searchType && searchType === 'p')
  {
    queryStringVariables.searchedPhone = searchedUser;
  }
  
  if (checkTimeframe(timeframe)) queryStringVariables.timeframe = timeframe;

  return API.get('newDashboard', '/customer', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      //'Access-Control-Allow-Origin': '*'
    }, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: queryStringVariables
  })
}
function checkTimeframe(timeframe) {
  return timeframe ? true : false
}
export default getWithPagination
