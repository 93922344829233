import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import { Row, Col, Layout} from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import Navbar from "../containers/Navbar";
import Summary from "./summary";
import Notifications from "../pages/Notifications";
import Operations from "../pages/Operations";
import Campaign from "../pages/Campaign";
import CreateCampaign from "../pages/Campaign/create";
import CreateNotification from "../pages/Notifications/create";
import ShowNotification from "../pages/Notifications/show";
import EditNotification from "../pages/Notifications/edit";
import EditCampaign from "../pages/Campaign/edit";


import indexStyles from './index.module.css'

const Routes = () => (
    <Router>
        <>
            <Navbar/>
            <Layout>
                <Layout.Content style={{padding: '0 50px'}}>
                    <Row>
                        <Col span={24}>
                            <Switch>
                                <Route exact path="/campaign/create">
                                    <CreateCampaign/>
                                </Route>
                                <Route exact path="/notifications/create">
                                    <CreateNotification/>
                                </Route>
                                <Route exact path="/notifications/:id/edit">
                                    <EditNotification/>
                                </Route>
                                <Route exact path="/notifications/:id">
                                    <ShowNotification/>
                                </Route>
                                <Route exact path="/notifications">
                                    <Notifications/>
                                </Route>
                                <Route exact path="/operations/create">
                                    <CreateNotification/>
                                </Route>
                                <Route exact path="/operations/:id/edit">
                                    <EditNotification/>
                                </Route>
                                <Route exact path="/operations/:id">
                                    <ShowNotification/>
                                </Route>
                                <Route exact path="/operations">
                                    <Operations/>
                                </Route>
                                <Route exact path="/campaign">
                                    <Campaign/>
                                </Route>
                                <Route exact path="/campaign/:id/edit">
                                    <EditCampaign/>
                                </Route>
                                <Route path="/">
                                    <Summary/>
                                </Route>
                            </Switch>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        </>
    </Router>
)

export default Routes;