import { API, Auth } from 'aws-amplify';

const postUserCognitoInfo = async (sub,cognitoGroup,alternativeNames) => {
    return API.post('dashboard', '/dashboard/users/' + sub, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        cognitoGroup : cognitoGroup,
        alternativeNames: alternativeNames
      } 
    });   
  }
const postUserInfoUpdate = async (sub,id,name,surname,email,birthDate,nationality,isbankCustomerNo,street,birthCountry,issuePlace,streetNr,issueCountry,zipCode,city,birthPlace,onboardStatus) => {
  return API.post('dashboard', '/dashboard/users/' + sub + "/update-user", {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
    },
    body: {
      id : id,
      name : name,
      surname: surname,
      email: email,
      birthDate: birthDate,
      nationality: nationality,
      isbankCustomerNo: isbankCustomerNo,
      street: street,
      birthCountry: birthCountry,
      issuePlace: issuePlace,
      streetNr: streetNr,
      issueCountry: issueCountry,
      zipCode: zipCode,
      city: city,
      birthPlace: birthPlace,
      onboardStatus: onboardStatus
    }
  });
}

export {
  postUserCognitoInfo,
  postUserInfoUpdate,
}
  