import React, { useState, useEffect } from 'react';
import {useParams, useHistory, Link} from "react-router-dom";
import {
    Popconfirm,
    Button,
    Col,
    Divider,
    Row,
    Space,
    Typography,
    Radio,
    Upload,
    Spin,
    notification as antNotification, Table
} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from "xlsx";
import _ from 'lodash';
import moment from "moment";
import {sendNotification as sendNotificationRequest, getNotificationHistory as getNotificationHistoryRequest} from "../../utils/notifications";

import notificationStyles from './notification.module.css'
import {postCampaignPinpoint} from "../../utils/campaign";
import { DatePicker } from 'antd';
// import 'moment/locale/tr';
// import de from 'antd/es/date-picker/locale/tr_TR';
const { RangePicker } = DatePicker;

const ShowNotification = () => {

    const { id } = useParams();
    const history = useHistory()
    const schedule = "";

    const [ notifications, setNotifications ] = useState([])
    const [stage, setStage] = useState(localStorage.getItem('stage'))
    const [items, setItems] = useState([]);
    const [ sendAllUsers, setSendAllUsers ] = useState(true);
    const [ fileUploading, setFileUploading ] = useState(false);
    const [ isSending, setIsSending ] = useState(false);
    const [ notificationHistory, setNotificationHistory ] = useState([]);
    const [ notificationHistoryLoading, setNotificationHistoryLoading ] = useState(false);
    //scheduleNotif
    const [ sendNow, setSendNow ] = useState(true);
    const [ scheduleType, setScheduleType ] = useState([]);
    const [ scheduleDate, setScheduleDate ] = useState(0);

    const [startDate, setStartDate] = useState(new Date());
    function onOk(value) {
        console.log('onOk: ', value);
        var someDate = new Date(value);
        someDate = someDate.getTime();
        setScheduleDate(someDate)
        console.log('onOk: ', someDate);
        console.log('onOk: ',scheduleDate );

    }

    function onChange(value, dateString) {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
    }


    useEffect(() => {
        getNotification();
        getNotificationHistory()
    }, []);

    const readExcel = (file) => {

        if (!file) {
            return false;
        }
        const promise = new Promise((resolve, reject) => {
            setFileUploading(true)
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });


                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets["users"];

                if (!ws) {
                    reject();
                    antNotification.error({
                        message: `Excel doesn't contain users Sheet`,
                    });
                    setFileUploading(false);

                }

                const data = XLSX.utils.sheet_to_json(ws, {header: 1, raw: true,});

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            setFileUploading(false);
            setItems(d.filter(x=> x.length > 0));
        });
    };

    const sendNotification = async () => {
        setIsSending(true)
        const data = items;
        const users = [];
        const bodyParams = [];
        data.map(item => {
            const [phone, ...params] = item;
            users.push(phone + "");
            bodyParams.push(params.map(String))
        })
        console.log(users)
        console.log(bodyParams)

        const body = {
            "stage": stage,
            "sendAllUsers": sendAllUsers,
            "users": !sendAllUsers ? users : [],
            "bodyParams": !sendAllUsers ? bodyParams : [],
            "messageId": id,
            "scheduleDate": scheduleDate,
            "scheduleType": scheduleType

        }

        console.log(body)

        await sendNotificationRequest(body)
            .then(response => {
                console.log(response)
                if(response.code === 0)
                {
                    setTimeout(() => {
                        antNotification.success({
                            message: 'Operations tablosuna başarıyla kaydedildi.',
                        });
                        setIsSending(false)
                        setItems([])
                    }, 1500)
                }
                else if(response.code === 1){
                    setTimeout(() => {
                        antNotification.success({
                            message: 'İşlem başarıyla gerçekleştirildi.',
                        });
                        setIsSending(false)
                        setItems([])
                    }, 1500)
                }else if(response.code === 2){
                    setTimeout(() => {
                        antNotification.error({
                            message: "Error, campaign couldn't create",
                        });
                        setIsSending(false)
                    }, 1500)
                }
            }).catch(error => {
                alert(error);
            })
    }

    const getNotification = () => {
        const notificationMessages = localStorage.getItem('notificationMessages');
        if(!notificationMessages) {
            history.push('/notifications')
        }

        const showingNotifications = JSON.parse(notificationMessages).filter(x => x.id == id);

        if(showingNotifications.length === 0) {
            history.push('/notifications')
        }
        setNotifications(showingNotifications)
    }

    const getNotificationHistory = async () => {
        setNotificationHistoryLoading(true)
        await getNotificationHistoryRequest(stage, id).then(data => {
            setNotificationHistory(_.sortBy(data, 'createDate').reverse());
            setNotificationHistoryLoading(false);
        })
    }

    const notificationHistoryColumns = [
        {
            title: 'createDate',
            dataIndex: 'createDate',
            key: 'createDate',
            render: (record) => {
                return <p>{new Date(record).toLocaleString('TR-tr')}</p>
            }
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'lastIndex',
            dataIndex: 'lastIndex',
            key: 'lastIndex',
            render: (lastIndex, record) => {
                return <p>{record.lastIndex}</p>
            }
        },
        {
            title: 'Hedef Kitle',
            dataIndex: 'type',
            key: 'type',
            render: (record) => {
                return <p>{record === 'all' ? 'Tüm Kullanıcılar' : 'Excel Listesi'}</p>
            }
        },
        {
            title: 'createUser',
            dataIndex: 'createUser',
            key: 'createUser',
        },
        {
            title: 'updateDate',
            dataIndex: 'updateDate',
            key: 'updateDate',
            render: (record) => {
                return <p>{new Date(record).toLocaleString('TR-tr')}</p>
            }
        },
    ]

    return (
        <>
            <Row>
                <Col span={12}>
                    <Row justify="space-between" align="middle">
                        <Typography.Paragraph>id: <b>{id}</b> | env: <b>{stage}</b></Typography.Paragraph>
                        <Button onClick={() => history.push(`/notifications/${id}/edit`)} type="primary">Edit</Button>
                    </Row>
                    {
                        notifications.map(notification => (
                            <>
                                <Divider orientation="left"><b>{notification.language}</b></Divider>
                                <Typography.Paragraph><b>{notification.title}</b></Typography.Paragraph>
                                <Typography.Paragraph>{notification.body}</Typography.Paragraph>
                            </>
                        ))
                    }
                </Col>
                <Col span={2}></Col>
                <Col span={10}>
                    <Row justify="space-between" align="middle">
                        <Typography.Paragraph>Send Notification</Typography.Paragraph>
                        {/*<Space direction="vertical" size={12}>*/}
                        {/*    <DatePicker*/}

                        {/*        selected={startDate}*/}
                        {/*        onOk={onOk}*/}
                        {/*        onChange={onChange}*/}
                        {/*        showTime={{ format: 'HH:mm' }}*/}
                        {/*        format="YYYY-MM-DD HH:mm"*/}
                        {/*        onChange={date => setStartDate(date)}*/}
                        {/*    />*/}
                        {/*</Space>*/}

                    </Row>
                    <Row justify="space-between" align="middle">

                        <Radio.Group defaultValue={sendNow} buttonStyle="solid" onChange={e => {
                            setItems([])
                            setSendNow(e.target.value)

                        }}>
                            <Radio.Button onClick={() => setScheduleType(null)} value={true}>Şimdi Gönder</Radio.Button>
                            <Radio.Button onClick={() => setScheduleType('scheduled')} value={false}>Sonra Gönder</Radio.Button>
                        </Radio.Group>
                    </Row>

                    {
                        !sendNow &&

                        <Row align="middle" className={notificationStyles.row}>
                            <Space direction="vertical" size={12}>

                                <DatePicker
                                    selected={startDate}
                                    onOk={onOk}
                                    onChange={onChange}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={date => setStartDate(date)}
                                />

                                <Typography.Paragraph>* Seçeceğiniz saat Türkiye saatine göre ayarlanacaktır. Türkiye ile Almanya arasında 2 saat fark bulunmaktadır. Almanya saatine göre ayarlamak istiyorsanız lütfen saat farkına göre ayarlama yaptıktan sonra OK butonuna basınız.
                                </Typography.Paragraph>

                            </Space>

                        </Row>
                    }
                    <Row className={notificationStyles.row}>
                        <Radio.Group defaultValue={sendAllUsers} buttonStyle="solid" onChange={e => {
                            setItems([])
                            setSendAllUsers(e.target.value)
                        }}>
                            <Radio.Button value={true}>Send All Users</Radio.Button>
                            <Radio.Button value={false}>Select From Excel</Radio.Button>
                        </Radio.Group>
                    </Row>
                    {
                        !sendAllUsers &&
                        <Row align="middle" className={notificationStyles.row}>
                            <input
                                type="file"
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    readExcel(file);
                                }}
                            />

                            {
                                fileUploading && <Spin tip={"Uploading..."}/>
                            }
                            {
                                !fileUploading && items.length > 0 && (
                                    <table style={{width: '100%'}}>
                                        <thead>
                                        <tr>
                                            <th width="25%"></th>
                                            <th width="25%">User</th>
                                            <th width="50%">Params</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{items[0][0]}</td>
                                            <td>{items[0].filter((item, index) => index !== 0).join(',')}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>.</td>
                                            <td>.</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>.</td>
                                            <td>.</td>
                                        </tr>
                                        <tr>
                                            <td>{items.length}</td>
                                            <td>{items[items.length - 1][0]}</td>
                                            <td>{items[items.length - 1].filter((item, index) => index !== 0).join(',')}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                )
                            }
                        </Row>
                    }

                    <Row align="middle" className={notificationStyles.row}>

                        {
                            !sendAllUsers && (fileUploading || items.length === 0) && (
                                <Button type="primary" size="large" danger disabled>
                                    Send
                                </Button>
                            )
                        }
                        {
                            !(!sendAllUsers && (fileUploading || items.length === 0)) && (
                                <Popconfirm
                                    title={() => <p>Are you sure to send this notification to {sendAllUsers ? 'all users' : 'selected users'}? | <b>{stage} environment</b></p>}
                                    onConfirm={() => sendNotification()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="primary" size="large" danger loading={isSending}>
                                        Send
                                    </Button>
                                </Popconfirm>
                            )
                        }
                    </Row>
                </Col>
            </Row>
            <Row justify="space-between" align="middle">
                <Divider orientation="center"><b>Notification History</b></Divider>
                <Button onClick={() => getNotificationHistory()} type="info">Refresh</Button>
            </Row>
            <Row>
                <Table loading={notificationHistoryLoading} dataSource={notificationHistory} columns={notificationHistoryColumns} />
            </Row>
        </>
    )
}

export default ShowNotification;