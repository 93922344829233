import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { DownloadOutlined, SaveOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import {Button, Divider, Row, Typography, Form, Input, Col, notification as antNotification} from "antd";

import notificationStyles from './notification.module.css'

import { createOrUpdateNotificationMessages } from "../../utils/notifications";

const EditNotification = () => {
    const { id } = useParams();
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const history = useHistory()
    const [ notifications, setNotifications ] = useState([])
    const [stage, setStage] = useState(localStorage.getItem('stage'))
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getNotification();
    }, []);

    const getNotification = () => {
        const notificationMessages = localStorage.getItem('notificationMessages');
        if(!notificationMessages) {
            history.push('/notifications')
        }

        const showingNotifications = JSON.parse(notificationMessages).filter(x => x.id == id);

        if(showingNotifications.length === 0) {
            history.push('/notifications')
        }
        setNotifications(showingNotifications)
    }

    const onFinish = (values) => {
        console.log('Success:', JSON.stringify(values));
        setLoading(true)
        notifications.map(async (notification) => {

            const itemsObject = {
                    "title": values[`${notification.language}_title`],
                    "body": values[`${notification.language}_body`],
                    "language": notification.language,
            }

            const screenCode = values[`${notification.language}_screenCode`]

            if(screenCode) {
                itemsObject["screenCode"] = screenCode;
            }

            const body = {
                "stage": stage ? stage : "dev",
                "id": notification.id,
                "items": [
                    itemsObject
                ]
            }

            await createOrUpdateNotificationMessages(body)
        })



        setTimeout(() => {
            antNotification.success({
                message: 'Success',
            });
            setLoading(false);
            history.push('/notifications')

        }, 1500)

    };

    return (
        <>
            <Row justify="center">
                <Col span="16">
                    <Row align="middle">
                        <Col span="4">
                            <Button onClick={() => history.goBack()} className={notificationStyles.back__icon}  icon={<ArrowLeftOutlined />} size="large">
                                Back
                            </Button>
                        </Col>
                        <Col span="12">
                            <Typography.Paragraph  style={{marginBottom: 0}}>You're editing the notification with <b> id: {id}</b> | env: <b>{stage}</b></Typography.Paragraph>
                        </Col>
                    </Row>
                    <Form {...layout} onFinish={onFinish}>
                        {
                            notifications.map(notification => (
                                <>
                                    <Divider orientation="left"><b>{notification.language}</b></Divider>
                                    <Form.Item initialValue={notification.id} name={`${notification.language}_id`} label="id" hidden>
                                        {/*<Input/>*/}
                                    </Form.Item>
                                    <Form.Item initialValue={notification.title} name={`${notification.language}_title`} label="Title">
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item initialValue={notification.body} name={`${notification.language}_body`} label="Body">
                                        <Input.TextArea showCount autoSize={{ minRows: 5, maxRows: 10 }}/>
                                    </Form.Item>
                                    <Form.Item initialValue={notification.screenCode} name={`${notification.language}_screenCode`} label="Screen Code">
                                        <Input/>
                                    </Form.Item>
                                </>
                            ))
                        }
                        <Form.Item>
                            <Button loading={loading} htmlType="submit" className={notificationStyles.save__icon} type="primary" icon={<SaveOutlined />} size="large">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <>

            </>
        </>
    )
}

export default EditNotification;