import { API, Auth } from 'aws-amplify'

const postGift = async (phoneNumber,giftValue) => {
    return API.post('dashboard', '/dashboard/gift', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      },
      body: {
        phoneNumber : phoneNumber,
        giftValue : giftValue
      } 
    })   
  }
  
  export default postGift
  