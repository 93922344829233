import {API, Auth} from 'aws-amplify'

const getNotificationMessages = async (stage="dev") => {

    return API.get('dashboard', `/dashboard/notification-messages?stage=${stage}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const getNotificationHistory = async (stage="dev", notificationMessageId) => {
    return API.get('dashboard', `/dashboard/notification-job?stage=${stage}&messageId=${notificationMessageId}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const createOrUpdateNotificationMessages = async (body) => {

    return API.post('dashboard', `/dashboard/notification-messages`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: body,
    })
}

const sendNotification = async (body) => {
    return API.post('dashboard', `/dashboard/send-notification`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: body,
    })
}

export {
    getNotificationMessages,
    getNotificationHistory,
    createOrUpdateNotificationMessages,
    sendNotification
}