import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { DownloadOutlined, SaveOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import {Button, Divider, Row, Typography, Form, Input, Col, notification as antNotification} from "antd";

import notificationStyles from './notification.module.css'

import { createOrUpdateNotificationMessages } from "../../utils/notifications";

const CreateNotification = () => {
    const { id } = useParams();
    const layout = {
        labelCol: {
            span: 4,
        },
        wrapperCol: {
            span: 16,
        },
    };
    const history = useHistory()
    const [ notifications, setNotifications ] = useState([])
    const [ loading, setLoading ] = useState(false);

    const languages = ["tr", "en", "de"]


    const onFinish = (values) => {
        setLoading(true)
        languages.map(async (language) => {

            const itemsObject = {
                "title": values[`${language}_title`],
                "body": values[`${language}_body`],
                "language": language,
            }

            const screenCode = values[`${language}_screenCode`]

            if(screenCode) {
                itemsObject["screenCode"] = screenCode;
            }

            const body = {
                "stage": "dev",
                "items": [
                    itemsObject
                ]
            }

            await createOrUpdateNotificationMessages(body)
            body.stage = "prod";
            await createOrUpdateNotificationMessages(body)
        })


        setTimeout(() => {
            antNotification.success({
                message: 'Success',
            });
            setLoading(false);
            history.push('/notifications')

        }, 1500)
    };

    return (
        <>
            <Row justify="center">
                <Col span="16">
                    <Row align="middle">
                        <Col span="4">
                            <Button onClick={() => history.goBack()} className={notificationStyles.back__icon}  icon={<ArrowLeftOutlined />} size="large">
                                Back
                            </Button>
                        </Col>
                    </Row>
                    <Form {...layout} onFinish={onFinish}>
                        {
                            languages.map(language => (
                                <>
                                    <Divider orientation="left"><b>{language}</b></Divider>
                                    <Form.Item initialValue="" name={`${language}_title`} label="*Title" rules={[{ required: true, message: "Field is required" }]}>
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item initialValue="" name={`${language}_body`} label="*Body"  rules={[{ required: true, message: "Field is required" }]}>
                                        <Input.TextArea showCount autoSize={{ minRows: 5, maxRows: 10 }}/>
                                    </Form.Item>
                                    <Form.Item initialValue="" name={`${language}_screenCode`} label="Screen Code">
                                        <Input/>
                                    </Form.Item>
                                </>
                            ))
                        }
                        <Form.Item>
                            <Button htmlType="submit" className={notificationStyles.save__icon} type="primary" icon={<SaveOutlined />} size="large">
                                Create
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <>

            </>
        </>
    )
}

export default CreateNotification;