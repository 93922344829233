import React, {useEffect, useState} from 'react';
import {Button, Divider, Form, Input, Modal, Space, Table} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import _ from 'lodash'
import {getCognitoUserDetail, getOperations, updateOperations} from "../../utils/operations";

import {Auth} from "aws-amplify";
import moment from "moment";
import {Link} from "react-router-dom";

var userRole;
var email;

//const [email, email] = useState([]);
const Operations = (props) => {

    var clickedItemId;

    const [stage, setStage] = React.useState(localStorage.getItem('stage') || "dev")
    const [loading, setLoading] = React.useState(false);

    const [ notificationMessages, setNotificationMessages ] = useState([]);
    const [ operationsTable, setOperationsTable ] = useState([]);
    const [ searchText, setSearchText] = useState(null);
    const [formButtonLoadingVisible, setFormButtonLoadingVisible] = useState(false);


    const onSearch = e => {
        setSearchText(e.target.value);
    }

    const onFinishConfirm = async values => {
        console.log(values)
        confirm("İşlemi Onayla", "Onaylıyor musunuz ?", 1, values);
    };

    const onFinishCancel = async values => {
        confirm("İşlemi iptal et", "Onaylıyor musunuz ?", 2, values);
    };

    const onFinishDetail = async values => {
        console.log("values.detail.toString()" + JSON.stringify(values.detail))
        var formattedJSON = JSON.stringify(values.detail)
        var detailText = "";
        if (formattedJSON != null && formattedJSON != ""){
            detailText = formattedJSON.replace(/[\[\]\{\}\"]+/g, '\n')
        }
        console.log("formattedJSON" + detailText)

        Modal.confirm({
            destroyOnClose: true,
            title: "İşlem detayı",
            icon: <ExclamationCircleOutlined/>,
            content: detailText,
            okText: 'Onayla',
            cancelText: 'İptal Et',
            confirmLoading: formButtonLoadingVisible,
            onOk: () => onFinishConfirm(values),
            onCancel: () => onFinishCancel(values)
        });
    };

    const confirm = (title, message, formType, formValues) => {
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined/>,
            content: message,
            okText: 'Evet',
            cancelText: 'Hayır',
            confirmLoading: formButtonLoadingVisible,
            onOk: () => handleOk(formType, formValues),
            onCancel: handleCancel
        });
    };

    const handleOk = async (formType, formValues) => {
        setFormButtonLoadingVisible(true);
        if (formType === 1) {
            setTimeout(async () => {
                await updateOperations(formValues.id,formValues.operationName, email,  "approved")
                    .then(response => {
                        setFormButtonLoadingVisible(false);
                        window.location.reload();
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        } else if (formType === 2) {
            setTimeout(async () => {
                //console.log("Formvalues" + JSON.stringify(values));
                console.log(formValues.id + " formValues.id")
                await updateOperations(formValues.id,formValues.operationName, email,  "canceled")
                    .then(response => {
                        setFormButtonLoadingVisible(false);
                        window.location.reload();
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        }
    };

    const handleCancel = () => {
        //console.log('Clicked cancel button');
        setFormButtonLoadingVisible(false);
    };

    const onFinishFailed = errorInfo => {
        alert('Failed: ', errorInfo);
        //console.log('Failed:', errorInfo);
    };

    const columns = [
        {
            title: 'Operation ID',
            dataIndex: 'operationId',
            key: 'operationId',
        },
        {
            title: 'Operation Name',
            dataIndex: 'operationName',
            key: 'operationName',
        },
        {
            title: 'Created User',
            dataIndex: 'createdUser',
            key: 'createdUser',
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'Approved User',
            dataIndex: 'approvedUser',
            key: 'approvedUser',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            key: 'action',
            render: (record) => {
               // if record varsa yoksa return

                console.log("userRole 111" +userRole)
                return (
                    <Space size="middle">
                        {
                            record.status === "waiting" && userRole === "admin" && (
                                <>
{/*                                    <Form
                                        name="control-hooks"
                                        onFinish={() => onFinishConfirm(record)}
                                        // onFinish={onFinish}
                                        //onFinishFailed={onFinishFailed}
                                    >
                                        <Form.Item>
                                            <Button
                                                block
                                                loading={formButtonLoadingVisible}
                                                //onClick={}
                                                type="primary"
                                                htmlType="submit">
                                                Onayla
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <Form
                                        name="control-hooks"
                                        onFinish={() => onFinishCancel(record)}
                                    >
                                        <Form.Item>
                                            <Button
                                                block
                                                loading={formButtonLoadingVisible}
                                                //onClick={}
                                                type="danger"
                                                htmlType="submit">
                                                İptal
                                            </Button>
                                        </Form.Item>
                                    </Form>*/}
                                    <Form
                                        name="control-hooks"
                                        onFinish={() => onFinishDetail(record)}
                                    >
                                        <Form.Item>
                                            <Button
                                                block
                                                loading={formButtonLoadingVisible}
                                                //onClick={}
                                                type="info"
                                                htmlType="submit">
                                                Detail
                                            </Button>
                                        </Form.Item>
                                    </Form>

                                </>
                            )
                        }

                    </Space>
                );
            }
        },
    ]

    useEffect(() => {
        var sub = getSubValue()
        console.log("sub" + sub)
        getAndMergeNotifications()

    }, [])


    const getSubValue = async () => {
        setFormButtonLoadingVisible(true);
        setTimeout(async () => {
           return await Auth.currentSession().then(data => {
               getCognitoDetails(data.getIdToken().payload.sub)
           }).catch(ex => {
                    setFormButtonLoadingVisible(false);
                });
        }, 500);

    };

    const getCognitoDetails = async (sub) => {
        setFormButtonLoadingVisible(true);
            setTimeout(async () => {
                await getCognitoUserDetail(sub)
                    .then(response => {
                        userRole = response.userDetail.users[0].role
                        email = response.userDetail.users[0].email
                        console.log("userRole "+ userRole)
                        console.log("email "+ email)
                        setFormButtonLoadingVisible(false);
                        return response.role;
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);

    };

    function compareElement(a,b){
        if (a.status === b.status) {
            return (a.createdDate < b.createdDate) ? 1 : -1;
        } else if (a.status=="approved") {
            return -1
        } else {
            return 1;
        }
    }

    function compareElementSecond(a,b){
        if (a.status === b.status) {
            return (a.createdDateAt < b.createdDateAt) ? 1 : -1;
        } else if (a.status=="approved") {
            return -1
        } else {
            return 1;
        }
    }

    const getAndMergeNotifications = () => {
        setLoading(true)
        getOperations().then(response => {
            const groupedNotifications = _.groupBy(response, 'id');
            const operationsTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.id !== '')
                if(turkishNotification) {
                    let showedNotification = {
                        key:id,
                        id: id,
                        approvedUser: turkishNotification.approvedUser,
                        createdDate: moment(turkishNotification.createdDate).format("DD-MM-YYYY HH:mm:ss"),
                        createdUser: turkishNotification.createdUser,
                        detail : turkishNotification.detail,
                        operationId: turkishNotification.operationId,
                        operationName: turkishNotification.operationName,
                        status: turkishNotification.status,
                        createdDateAt : turkishNotification.createdDate
                    }
                    operationsTable.push(showedNotification)
                }
            });
            response.sort(compareElement)
            operationsTable.sort(compareElementSecond)
            setNotificationMessages(response);
            localStorage.setItem('operationsList', JSON.stringify(response));
            setOperationsTable(operationsTable)
            setLoading(false)
        }).catch(error => {
            alert(error);
        });
    }
    return (
        <>
            <Divider>Operations</Divider>

            <Table loading={loading} dataSource={searchText ? operationsTable.filter(x => x.event.includes(searchText)).reverse() : operationsTable.sort(
                function(a, b) {
                    if (a.status === b.status) {
                        // Price is only important when cities are the same
                        return a.createdDate - b.createdDate;
                    }
                    return (b.status > a.status) ? 1 : -1;
                })} columns={columns} />
        </>
    );
}

export default Operations;

//operationsTable.sort((a, b) => b.status.localeCompare(a.status) || a.createdDate - b.createdDate)