import {API, Auth} from 'aws-amplify'

const getCampaign = async (stage="dev") => {

    return API.get('dashboard', `/dashboard/campaign/get-campaigns?stage=${stage}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const getPinpointCampaignType = async (stage="dev") => {
    return API.get('dashboard', `/dashboard/campaign/get-campaigns-type?stage=${stage}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const getCampaignPinpoint = async (stage="dev") => {

    return API.get('dashboard', `/dashboard/campaign/get-campaigns-pinpoint?stage=${stage}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const postCampaignPinpoint = async (body) => {
    return API.post('dashboard', `/dashboard/campaign/post-campaigns`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: body,
    })
}

const updatePinpointCampaign = async (body) => {

    return API.post('dashboard', `/dashboard/campaign/post-campaigns-status`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: body,
    })
}

const getCampaignUsage = async (stage="dev",earningType = "voucher",campaignId= "") => {

    return API.get('dashboard', `/dashboard/campaign/get-campaign-usage?stage=${stage}&earningType=${earningType}&campaignId=${campaignId}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const getCampaignUsagePrice = async (stage="dev",userId="dev",campaignId= "") => {

    return API.get('dashboard', `/dashboard/campaign/get-campaign-usage/price?stage=${stage}&userId=${userId}&campaignId=${campaignId}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}


export {
    getCampaign,
    getPinpointCampaignType,
    getCampaignPinpoint,
    postCampaignPinpoint,
    updatePinpointCampaign,
    getCampaignUsage,
    getCampaignUsagePrice
}