import {API, Auth} from 'aws-amplify'

const getOperations = async () => {

    return API.get('dashboard', `/dashboard/operations`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}
const getCognitoUserDetail = async (sub) => {

    return API.get('dashboard', '/dashboard/operations/' + sub, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        }
    })
}

const updateOperations = async (operationID,operationName,approvedUser,operationStatus) => {

    return API.post('dashboard', `/dashboard/operations`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
            id:operationID,
            operationName:operationName,
            approvedUser : approvedUser,
            operationStatus : operationStatus
        }
    })
}



export {
    getOperations,
    getCognitoUserDetail,
    updateOperations
}