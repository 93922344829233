import React from 'react'
import './Modal.css'

const Modal = props => {
    const showHideClassName = props.show ? 'modal display-block' : 'modal display-none'

    return (
        
        <div className={showHideClassName} onClick={props.handleClose} >
          <section className='modal-main'>    
         
            <p>{props.result}</p>
    
            <button className="closeButton"
              onClick={props.handleClose}
            >
              X
            </button>
          </section>
        </div>
      )
    }

export default Modal