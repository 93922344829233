import { API, Auth } from 'aws-amplify'

const postCustomerInfo = async (sub,segmentType,segmentRefCode) => {

    return API.post('dashboard', '/dashboard/users/' + sub + "/post-customer", {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        },
        body: {
            segmentType : segmentType,
            segmentRefCode: segmentRefCode
        }
    })
}

export default postCustomerInfo