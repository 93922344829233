import React from 'react';
import styles from '../../routes/summary/Summary.module.scss';
import classNames from 'classnames'

const StatusTag = ({ status }) => (
    <span
      className={classNames(
        styles['tag'],
        styles['is-rounded'],
        styles['is-white'],
        {
          [styles['is-info']]: status === '0',
          [styles['is-warning']]: status === '1',
          [styles['is-success']]: status === 'completed',
          [styles['is-danger']]: status === 'initial'
        },
        styles['circle-status-tag']
      )}
    >
      &nbsp;
    </span>
  )

  export default StatusTag;