import React, {useState, useEffect} from 'react';
import {Button, Modal, Form, Input, Tabs, Descriptions, Badge, Table, InputNumber, Select, Collapse, Space} from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import getUserTransactions from '../../utils/getUserTransactions';
import getUserCommTransactions from '../../utils/getUserCommTransactions';
import moment from 'moment';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import colTransactions from './TransactionsColumns';
import colCommTransactions from './CommissionTransactionsColumns';
import colLimit from './LimitColumns';
import getUserLimitAmount from "../../utils/getUserLimitAmount";
import getUserDetail from "../../utils/getUserDetail";
import EdiText from 'react-editext'
import 'antd/dist/antd.css';
import Text from "antd/es/typography/Text";


const {Panel} = Collapse;
const {Option} = Select;
const {TabPane} = Tabs;

const UserDetail = (props) => {

    const [form] = Form.useForm();
    const [formGift] = Form.useForm();
    const [formTransferNum] = Form.useForm();
    const [formUpdateForm] = Form.useForm();
    const [formSegmentType] =Form.useForm();
    const [formTransactionRef] =Form.useForm();
    const [formDeleteUser] =Form.useForm();
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState({ name: 'Wake up, Neo...' })
    const [ record, setRecord ] = useState([])


    var newName = "";
    var newSurname = "";
    var newEmail = "";
    var newBirthDate = "";
    var newNationality = "";
    var newIsbankCustomerNo = "";
    var newStreet = "";
    var newBirthCountry = "";
    var newIssuePlace = "";
    var newStreetNR = "";
    var newIssueCountry = "";
    var newZipCode = "";
    var newCity = "";
    var newBirthPlace = "";
    var segmentType = "";
    var segmentRefCode = "";
    var newOnboardStatus = "";

    var paymentRef = "";
    var command = "";

    const columnsTransaction = [
        {
            title: 'Tarih',
            dataIndex: 'newDate',
            key: 'newDate',
            sorter: (a, b) => a.date - b.date,
            sortDirections: ['descend', 'ascend']


        },
        {
            title: 'Finapi Status',
            dataIndex: 'status',
            key: 'status',
            filters: [
                {
                    text: 'Completed',
                    value: 'completed',
                },
                {
                    text: 'Initial',
                    value: 'initial',
                },
                {
                    text: 'Failed',
                    value: 'failed',
                },
            ],

            onFilter: (value, record) => record.status.indexOf(value) === 0,
            sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title:"isbankStatus",
            dataIndex:"isbankStatus",
            key:"isbankStatus",
            filters: [
                {
                    text: 'Başarılı',
                    value: '0',
                },
                {
                    text: 'Bekliyor',
                    value: '1',
                },
                {
                    text: 'Manuel',
                    value: '10',
                },

            ],
        },
        {
            title:"Tutar",
            dataIndex:"amount",
            key:"amount",
            sorter: (a, b) => a.amount - b.amount,
            sortDirections: ['descend', 'ascend']

        },
        {
            title: 'Action',
            key: 'action',
            // render: (record) => {
            //     // if record varsa yoksa return
            //
            //     return (
            //         <Space size="middle">
            //             {
            //                 (record.isbankStatus === null || record.isbankStatus === undefined) && record.isbankRecord !== "SUCCESS" && (
            //                     <>
            //                         <Form
            //                             name="control-hooks"
            //                             onFinish={() => cancelandTrigger(record)}
            //                         >
            //                             <Form.Item>
            //                                 <Button
            //                                     block
            //                                     loading={formButtonLoadingVisible}
            //                                     //onClick={}
            //                                     type="info"
            //                                     htmlType="submit">
            //                                     Detail
            //                                 </Button>
            //                             </Form.Item>
            //                         </Form>
            //
            //                     </>
            //                 )
            //             }
            //
            //         </Space>
            //     );
            // }
            render(record)  {
                const isTrigger = (record.isbankStatus === null || record.isbankStatus === undefined) && record.isbankRecord !== "SUCCESS"
                const isCancel = record.status === "completed" && record.isbankRecord === "SUCCESS" && ( record.isbankStatus === "1" || record.isbankStatus === "10" )
                return (
                    <div>

                        {isTrigger ? (
                            <Form
                                name="control-hooks"
                                onFinish={() => triggerTransaction(record)}
                            >
                                <Form.Item>
                                    <Button
                                        block
                                        loading={formButtonLoadingVisible}
                                        //onClick={}
                                        type="primary"
                                        htmlType="submit">
                                        Tetikle
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : isCancel ? (
                            <Form
                                name="control-hooks"
                                onFinish={() => cancelandTrigger(record)}
                            >
                                <Form.Item>
                                    <Button
                                        block
                                        loading={formButtonLoadingVisible}
                                        //onClick={}
                                        type="danger"
                                        disabled
                                        htmlType="submit">
                                        İptal
                                    </Button>
                                </Form.Item>
                            </Form>
                        ) : (

                            <div>DISABLED</div>
                            )}
                    </div>
                );
            }
        },
    ];

    const onSaveName = val => {
        newName = val;
        console.log('Edited Value -> ', newName)
    }
    const onSaveSurname = val => {
        newSurname = val;
        console.log('Edited Value -> ', newSurname)
    }
    const onSaveEmail = val => {
        newEmail = val;
        console.log('Edited Value -> ', newEmail)
    }

    const onSaveBirthDate = val => {
        newBirthDate = val;
        console.log('Edited Value -> ', newBirthDate)
    }

    const onSaveNationality = val => {
        newNationality = val;
        console.log('Edited Value -> ', newNationality)
    }

    const onSaveIsbankCustomerNo = val => {
        newIsbankCustomerNo = val;
        console.log('Edited Value -> ', newIsbankCustomerNo)
    }

    const onSaveStreet = val => {
        newStreet = val;
        console.log('Edited Value -> ', newStreet)
    }

    const onSaveBirthCountry = val => {
        newBirthCountry = val;
        console.log('Edited Value -> ', newBirthCountry)
    }
    const onSaveIssuePlace = val => {
        newIssuePlace = val;
        console.log('Edited Value -> ', newIssuePlace)
    }
    const onSaveStreetNR = val => {
        newStreetNR = val;
        console.log('Edited Value -> ', newStreetNR)
    }
    const onSaveIssueCountry = val => {
        newIssueCountry = val;
        console.log('Edited Value -> ', newIssueCountry)
    }
    const onSaveZipCode = val => {
        newZipCode = val;
        console.log('Edited Value -> ', newZipCode)
    }
    const onSaveCity = val => {
        newCity = val;
        console.log('Edited Value -> ', newCity)
    }
    const onSaveBirthPlace = val => {
        newBirthPlace = val;
        console.log('Edited Value -> ', newBirthPlace)
    }
    const onSaveOnboardStatus = val => {
        if(val=="initial" || val=="0" || val=="waiting" || val=="completed" || val=="rejected"){
            newOnboardStatus = val;
            console.log('Edited Value -> ', newOnboardStatus)
        }
    }
    const onSaveSegmentType = val => {
        segmentType = val;
        console.log('Edited Value -> ', segmentType)
    }
    const onSaveSegmentRefCode = val => {
        segmentType = val;
        console.log('Edited Value -> ', segmentRefCode)
    }


    useEffect(() => {
        form.setFieldsValue({
            cognitoGroup: props.user.cognitoGroup,
            alternativeNames: props.user.alternativeNames,

        });
        formGift.setFieldsValue({
            giftValue: 0
        });

        formSegmentType.setFieldsValue({
            segmentType: props.user.segmentType,
            segmentRefCode :props.user.segmentRefCode
        })

        formTransferNum.setFieldsValue({
            newPhoneNum: ''
        });
    }, [form, props.user, formGift,formTransferNum])

    var newUserCreateDate = moment(props.user.userCreateDate).format("DD-MM-YYYY HH:mm:ss");
    props.user.newUserCreateDate = newUserCreateDate;

    var newUserLastModifiedDate = moment(props.user.userLastModifiedDate).format("DD-MM-YYYY HH:mm:ss");
    props.user.newUserLastModifiedDate = newUserLastModifiedDate;

    const [userTransactionResponse, setUserTransactionResponse] = useState([]);
    const [usersLastLimits, setUsersLastLimits] = useState([]);
    const [userCommTransactionResponse, setUserCommTransactionResponse] = useState([]);
    const [userTxnLimitAmountResponse, setUserTxnLimitAmountResponse] = useState([]);
    const [userMonthlyTxnLimitAmountResponse, setUserMonthlyTxnLimitAmountResponse] = useState([]);
    const [userIsbankTxnLimitAmountResponse, setUserIsbankTxnLimitAmountResponse] = useState([]);
    const [userIsbankMonthlyTxnLimitAmountResponse, setUserIsbankMonthlyTxnLimitAmountResponse] = useState([]);
    const [userTotalLimitAmountResponse, setuserTotalLimitAmountResponse] = useState([]);
    const [userLegitimeResponse, setUserLegitimeResponse] = useState([]);
    const [tableLoadingVisible, setTableLoadingVisible] = useState(false);
    const [formButtonLoadingVisible, setFormButtonLoadingVisible] = useState(false);

    let badgeStatus = props.user.onboardStatus === 'initial' ? "processing" :
        props.user.onboardStatus === 'waiting' ? "warning" :
            props.user.onboardStatus === 'completed' ? "success" :
                props.user.onboardStatus === 'rejected' ? "error" :
                    "default";

    let emailVerifiedStatus = props.user.emailVerified === "true" ? "success" : "error";
    let phoneVerifiedStatus = props.user.phoneNumberVerified === "true" ? "success" : "error";

    const badgeTabTitle = (
        <Badge status={badgeStatus} text="Kullanıcı Bilgileri" title="deneme"/>
    );

    const badgeTransactionCount = (
        <Badge count={props.transactionCount} style={{backgroundColor: '#52c41a'}} offset={[15, 0]}>İşlemler</Badge>
    )

    const badgeTransactionGiftCount = (
        <Badge count={props.commTransactionCount} offset={[10, 0]}>Hediyeler</Badge>
    )

    const badgeLimitAmount = (
        <Badge offset={[10, 0]}>Limit</Badge>
    )

    const badgeOther = (
        <Badge offset={[10, 0]}>Diğer</Badge>
    )

    const confirm = (title, message, formType, formValues) => {
        Modal.confirm({
            title: title,
            icon: <ExclamationCircleOutlined/>,
            content: message,
            okText: 'Evet',
            cancelText: 'Hayır',
            confirmLoading: formButtonLoadingVisible,
            onOk: () => handleOk(formType, formValues),
            onCancel: handleCancel
        });
    };

    const getUserOtherTabsCall = async (sub, key) => {
        setTableLoadingVisible(true);
        if (key === "2") {
            setUserTransactionResponse([]);
            getUserTransactions(sub)
                .then(response => {
                    setTableLoadingVisible(false);
                    if (response !== null) {
                        let newArray = [...response];
                        newArray = newArray.map(x => {
                            var readableDate = new Date(x.date);
                            var newDate = moment(readableDate).format("DD-MM-YYYY HH:mm:ss");
                            return {...x, "key": x.id.toString(), "newDate": newDate};
                        });
                        setUserTransactionResponse(newArray);
                    }
                }).catch(error => {
                setTableLoadingVisible(false);
                alert(error);
            });
        } else if (key === "3") {
            getUserCommTransactions(sub)
                .then(response => {
                    setTableLoadingVisible(false);
                    if (response !== null) {
                        let newArray = [...response];
                        newArray = newArray.map(x => {
                            paymentRef = x.isbankStatus
                            var readableDate = new Date(x.CreationTime);
                            var newDate = moment(readableDate).format("DD-MM-YYYY HH:mm:ss");
                            return {...x, "key": x.id.toString(), "newDate": newDate};
                        });
                        setUserCommTransactionResponse(newArray);
                    }
                }).catch(error => {
                setTableLoadingVisible(false);
                alert(error);
            });
        } else if (key === "4") {
            setUserTxnLimitAmountResponse("");
            setUserMonthlyTxnLimitAmountResponse("");
            setUserIsbankTxnLimitAmountResponse("");
            setUserIsbankMonthlyTxnLimitAmountResponse("");
            getUserLimitAmount(sub)
                .then(response => {
                    setTableLoadingVisible(false);
                    if (response !== null) {
                        var isbankTxnLimit = response.txnLimit;
                        var isbankMontlyTxnLimit = response.monthlyTxnLimit;
                        var txnLimit = response.ruutTxnLimit;
                        var montlyTxnLimit = response.ruutMonthlyTxnLimit;
                        setUserTxnLimitAmountResponse(txnLimit+" €");
                        setUserMonthlyTxnLimitAmountResponse(montlyTxnLimit+" €");
                        setUserIsbankTxnLimitAmountResponse(isbankTxnLimit+" €");
                        setUserIsbankMonthlyTxnLimitAmountResponse(isbankMontlyTxnLimit+" €");
                        if(txnLimit > 10001){
                            setUserLegitimeResponse("Y");
                        }else {
                            setUserLegitimeResponse("N");
                        }

                    }
                }).catch(error => {
                setTableLoadingVisible(false);
                setUserTxnLimitAmountResponse("Error");
            });
            getUserTransactions(sub)
                .then(response => {
                    setTableLoadingVisible(false);
                    if (response !== null) {
                        //last 32 days
                        var priordate = new Date(new Date().getTime()-(32*24*60*60*1000));

                        console.log("Date().getTime() ", new Date().getTime()) ;
                        console.log("priordate ", priordate) ;
                        let newArray = [...response];
                        var last30DaysArray =  newArray.filter(function(x) {
                            return x.date >= priordate && x.status === "completed";
                        });

                        last30DaysArray = last30DaysArray.map(x => {
                            var readableDate = new Date(x.date);
                            var newDate = moment(readableDate).format("DD-MM-YYYY HH:mm:ss");
                            return {...x, "key": x.id.toString(), "readableDate": newDate};
                        });

                        last30DaysArray = last30DaysArray.map(x => {
                            var next32Day = new Date(x.date+(32*24*60*60*1000));
                            var readableDate = new Date(next32Day);
                            var newDate = moment(readableDate).format("DD-MM-YYYY");
                            return {...x, "key": x.id.toString(), "newDate": newDate};
                        });

                        last30DaysArray = last30DaysArray.map(x => {
                            var isbanksStatus = switchResult(x.isbankStatus);
                            return {...x, "key": x.id.toString(), "isbankStatus": isbanksStatus};
                        });

                        last30DaysArray.sort(function (x, y) {
                            return x.date - y.date;
                        });
                        console.log("last30DaysArray ", last30DaysArray)
                        setUsersLastLimits(last30DaysArray);
                    }
                }).catch(error => {
                setTableLoadingVisible(false);
                alert(error);
            });
        }else if (key === "5") {
            getUserDetail(sub)
                .then(response => {
                    setTableLoadingVisible(false);
                    if (response !== null) {
                  if (response.userDetail.users[0].enabled === false){

                  }
                    }
                }).catch(error => {
               setTableLoadingVisible(false);
               // setUserLimitAmountResponse("Error");
            });
        }
    }

    function switchResult(status){
        switch(status) {
            case "0":
                return "İşlem tamamlandı"
            case "1":
                return "İşlem devam etmekte"
            case "2":
                return "İşlem bulunamadı"
            case "5":
                return "İşlem bekliyor"
            case "9":
                return "İşlem iptal edildi."
            case "10":
                return "SEPA transferi bekleniyor"
            default:
                return "Bulunamadı"
    }}

    const onFinish = async values => {
        confirm("Güncelleme", "Onaylıyor musunuz ?", 1, values);
    };

    const onFinishgiftForm = async values => {
        confirm("Hediye Verme", "Onaylıyor musunuz ?", 2, values);
    };

    const areYouSureToTransferNumber = async values => {
        console.log(values , "transfer")
        confirm("Kullanıcının numarasını güncelliyorsun!", "Devam etmek konusunda emin misin?", 3, values);
    };

    const onFinishDisabledUser = async values => {
        confirm("Kullanıcıyı Silmeyi", "Onaylıyor musunuz ?", 4, values);
    };

    const areYouSureToUpdateUserInfo = async values => {
        console.log(values , "güncelleme")
        confirm("Kullanıcının bilgilerini güncelliyorsun!", "Onaylıyor musunuz ?", 5, values);
    }
    const segmentTypeChange = async values => {
        confirm("Kullanıcının Segment bilgilerini güncelliyorsun!", "Onaylıyor musunuz ?", 6, values);
    }
    const sendEmailCognitoInfo = async values => {
        confirm("İşbank AG'ye mail atılacak!", "Onaylıyor musunuz ?", 7, values);
    }

    const cancelandTrigger = async values => {
        console.log(values)
        confirm("İşlemi İptal Edeceksiniz!", "Onaylıyor musunuz ?", 8, values);
    }

    const triggerTransaction = async values => {
        console.log(values)
        confirm("İşlemi Tetikleyeceksiniz!", "Onaylıyor musunuz ?", 9, values);
    }

    const onFinishDisableUserControl = async values => {
        if ( props.user.onboardStatus === "completed"){
            console.log("----")
            Modal.confirm({
                destroyOnClose: true,
                title: <Text style={{fontWeight: 'bold', fontSize: '18px'}}> İşbank AG haber verdiniz mi?  Haber verdiyseniz Evet butonunu tıklayarak müşteriyi silebilirsiniz. Eğer haber vermediyseniz  önce haber verin lütfen! </Text>,
                icon: <ExclamationCircleOutlined/>,
                content:  <Button  block
                                  loading={formButtonLoadingVisible}
                                  type="danger"
                                  onClick={sendEmailCognitoInfo}>İşbank AG'ye Haber Ver.</Button>  ,
                okText: 'Evet',
                cancelText: 'Çıkış',
                confirmLoading: formButtonLoadingVisible,
                onOk: () => onFinishDisabledUser(values),
                onCancel: () => handleCancel(values),
            });

        } else {
            onFinishDisabledUser(values)

        }
    };


    const onFinishFailed = errorInfo => {
        alert('Failed: ', errorInfo);
        //console.log('Failed:', errorInfo);
    };




    const handleOk = async (formType, formValues) => {
        setFormButtonLoadingVisible(true);
        if (formType === 1) {
            setTimeout(async () => {
                let values = formValues;
                await props.onUpdateUserCognitoInfoClicked(props.user.sub, values.cognitoGroup, values.alternativeNames)
                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        } else if (formType === 2) {
            setTimeout(async () => {
                let values = formValues;
                //console.log("Formvalues" + JSON.stringify(values));
                await props.onGiveGiftClicked(props.user.phoneNumber, props.user.name, props.user.surname, values.giftValue)
                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        }else if (formType === 3) {
            setTimeout(async () => {
                let values = formValues;
                //console.log("Formvalues" + JSON.stringify(values));
                await props.onTransferNumberClicked(props.user.userId, props.user.phoneNumber, values.newPhoneNum)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        }
        else if (formType === 4) {
        setTimeout(async () => {
            let values = formValues;
            //console.log("Formvalues" + JSON.stringify(values));
            await props.deleteCognitoUser(props.user.userId)
                .then(response => {
                    setFormButtonLoadingVisible(false);
                }).catch(ex => {
                    setFormButtonLoadingVisible(false);
                });
        }, 500);
    } else if (formType === 5) {
            setTimeout(async () => {
                let values = formValues;
                //console.log("Formvalues" + JSON.stringify(values));
                //sub,id,name,surname,email,birthDate,nationality,isbankCustomerNo,street,birthCountry,issuePlace,locale,streetNr,issueCountry,zipCode,city,birthPlace
                await props.onUpdateUserInfoClicked(props.user.phoneNumber, props.user.id, newName,newSurname,newEmail,newBirthDate,newNationality,newIsbankCustomerNo,newStreet,newBirthCountry,newIssuePlace,newStreetNR,newIssueCountry,newZipCode,newCity,newBirthPlace,newOnboardStatus)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        } else if (formType === 6) {
            setTimeout(async () => {
                let values = formValues;
                await props.onSegmentTypeChange(props.user.phoneNumber,values.segmentType,values.segmentRefCode)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        } else if (formType === 7) {
            setTimeout(async () => {
                let values = formValues;
                await props.sendEmailUserInfo(props.user.phoneNumber,props.user.isbankCustomerNum,props.user.name, props.user.surname,props.user.email)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        }else if (formType === 8){
            setTimeout(async () => {
                let values = formValues;
                await props.onCancelAndTriggerTransaction(props.user.phoneNumber,"CANCEL", values.payment_ref)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });

            }, 500);
        }else if (formType === 9){
            setTimeout(async () => {
                let values = formValues;
                await props.onCancelAndTriggerTransaction(props.user.phoneNumber,"TRIGGER", values.payment_ref)

                    .then(response => {
                        setFormButtonLoadingVisible(false);
                    }).catch(ex => {
                        setFormButtonLoadingVisible(false);
                    });
            }, 500);
        }

};
    const handleCancel = () => {
        //console.log('Clicked cancel button');
        setFormButtonLoadingVisible(false);
    };
//<Descriptions.Item label="cognitoGroup">{props.user.cognitoGroup}</Descriptions.Item>
//<Descriptions.Item label="alternativeNames">{props.user.alternativeNames}</Descriptions.Item>
//<Descriptions.Item label="endpointarn">{props.user.endpointarn}</Descriptions.Item>
//<Descriptions.Item label="sub">{props.user.sub}</Descriptions.Item>

    return (
        <Modal
            visible={props.visible}
            title={props.user.name + " " + props.user.surname + " | " + props.user.phoneNumber}
            footer={null} //No Buttons
            //okText="Create"
            //cancelText="Cancel"
            onCancel={() => {
                props.onCancel();
            }}
            destroyOnClose={true}
            /*onOk={() => {
              form.validateFields()
              .then(values => {
                form.resetFields();
                //onCreate(values);
              }).catch(info => {
                console.log('Validate Failed:', info);
              });
            }}*/
        >
            <Tabs defaultActiveKey={"1"}
                  onChange={props.user !== undefined ? (key) => getUserOtherTabsCall(props.user.userId, key) : null}
                  size={'small'}>
                <TabPane tab={badgeTabTitle} key="1">
                    {/* <Form
                        form={form}
                        name="control-hooks"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item
                            name="alternativeNames"
                            label="Alternatif İsim">
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name="cognitoGroup"
                            label="Cognito Group">
                            <Select
                                placeholder="Cognito grubu seçiniz"
                                allowClear>
                                <Option value="DEFAULT">Default</Option>
                                <Option value="EMPLOYEE">Employee</Option>
                                <Option value="CEO">Ceo</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                loading={formButtonLoadingVisible}
                                type="primary"
                                htmlType="submit">
                                Kaydet
                            </Button>
                        </Form.Item>
                    </Form> */}

                    {/* <Button
                        block
                        type="primary"
                        className='button is-small is-warning'
                        onClick={() => {
                            setEditing(e => !e )
                        }}>
                        Düzenle
                    </Button> */}
                    <Descriptions column={1} bordered={true} size={'small'}>
                        <Descriptions.Item label="İsim">
                            <EdiText
                                type='text'
                                value={props.user.name}
                                onSave={onSaveName}
                                editing={editing}
                            /></Descriptions.Item>
                        <Descriptions.Item label="Soyisim">
                            <EdiText
                            type='text'
                            value={props.user.surname}
                            onSave={onSaveSurname}
                            editing={editing}
                            /></Descriptions.Item>

                        <Descriptions.Item label="Telefon No">
                            <Badge status={phoneVerifiedStatus}></Badge>
                            {" " + props.user.userId}
                        </Descriptions.Item>

                        <Descriptions.Item label="Email">
                            <EdiText
                                type='text'
                                value={props.user.email}
                                onSave={onSaveEmail}
                                editing={editing}
                            />

                        </Descriptions.Item>

                        <Descriptions.Item label="Oluşturma Tarihi">{props.user.newUserCreateDate}</Descriptions.Item>
                        <Descriptions.Item
                            label="Son Güncelleme Tarihi">{props.user.newUserLastModifiedDate}</Descriptions.Item>
                        <Descriptions.Item label="Doğum Tarihi">
                            <EdiText
                            type='text'
                            value={props.user.birthDate}
                            onSave={onSaveBirthDate}
                            editing={editing}
                        /></Descriptions.Item>
                        <Descriptions.Item label="Uyruk">
                            <EdiText
                                type='text'
                                value={props.user.nationality}
                                onSave={onSaveNationality}
                                editing={editing}
                            /></Descriptions.Item>
                        <Descriptions.Item label="İş Bank Müşteri No">
                            <EdiText
                                type='text'
                                value={props.user.isbankCustomerNum}
                                onSave={onSaveIsbankCustomerNo}
                                editing={editing}
                            /></Descriptions.Item>
                        <Descriptions.Item label="Sokak">
                            <EdiText
                                type='text'
                                value={props.user.street}
                                onSave={onSaveStreet}
                                editing={editing}
                            /></Descriptions.Item>
                    </Descriptions>

                    <Form
                        form={formSegmentType}
                        name="control-hooks"
                        onFinish={segmentTypeChange}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item
                            name="segmentType"
                            label="Segment Type">
                            <Select
                                placeholder="Segment type seçiniz"
                                allowClear>
                                <Option value="normal">normal</Option>
                                <Option value="employee">employee</Option>
                                <Option value="ceo">ceo</Option>
                                <Option value="fieldAmbassador">fieldAmbassador</Option>
                                <Option value="brandAmbassador">brandAmbassador</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="segmentRefCode"
                            initialValue={props.customerSegmentTypeCount.SegmentRefCode}
                            label="Segment Ref Code">
                            <Input/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                loading={formButtonLoadingVisible}
                                type="danger"
                                disabled
                                htmlType="submit">
                                Segment Bilgilerini Kaydet
                            </Button>
                        </Form.Item>
                    </Form>


                    <Collapse>
                        <Panel header="Daha fazla bilgi için tıklayın" key="1">
                            <Descriptions column={1} bordered={true} size={'small'}>
                                <Descriptions.Item label="Doğum Ülkesi">
                                    <EdiText
                                        type='text'
                                        value={props.user.birthCountry}
                                        onSave={onSaveBirthCountry}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="issuePlace">
                                    <EdiText
                                        type='text'
                                        value={props.user.issuePlace}
                                        onSave={onSaveIssuePlace}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="locale">{props.user.locale}</Descriptions.Item>
                                <Descriptions.Item label="streetNr">
                                    <EdiText
                                        type='text'
                                        value={props.user.streetNr}
                                        onSave={onSaveStreetNR}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="validTo">{props.user.validTo}</Descriptions.Item>
                                <Descriptions.Item
                                    label="agreementVersNum">{props.user.agreementVersNum}</Descriptions.Item>
                                <Descriptions.Item label="identType">{props.user.identType}</Descriptions.Item>
                                <Descriptions.Item label="issueCountry">
                                    <EdiText
                                        type='text'
                                        value={props.user.issueCountry}
                                        onSave={onSaveIssueCountry}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="identNr">{props.user.identNr}</Descriptions.Item>
                                <Descriptions.Item label="zipCode">
                                    <EdiText
                                        type='text'
                                        value={props.user.zipCode}
                                        onSave={onSaveZipCode}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="kindEmpl">{props.user.kindEmpl}</Descriptions.Item>
                                <Descriptions.Item label="issueDate">{props.user.issueDate}</Descriptions.Item>
                                <Descriptions.Item label="city">
                                    <EdiText
                                        type='text'
                                        value={props.user.city}
                                        onSave={onSaveCity}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="osVersion">{props.user.osVersion}</Descriptions.Item>
                                <Descriptions.Item label="gender">{props.user.gender}</Descriptions.Item>
                                <Descriptions.Item
                                    label="releaseVersion">{props.user.releaseVersion}</Descriptions.Item>
                                <Descriptions.Item label="countryCode">{props.user.countryCode}</Descriptions.Item>
                                <Descriptions.Item label="birthPlace">
                                    <EdiText
                                        type='text'
                                        value={props.user.birthPlace}
                                        onSave={onSaveBirthPlace}
                                        editing={editing}
                                    /></Descriptions.Item>
                                <Descriptions.Item label="osType">{props.user.osType}</Descriptions.Item>
                                <Descriptions.Item label="onboardStatus">
                                    <EdiText
                                        type='text'
                                        value={props.user.onboardStatus}
                                        onSave={onSaveOnboardStatus}
                                        editing={editing}
                                    />
                                </Descriptions.Item>
                                <Descriptions.Item label="username">{props.user.username}</Descriptions.Item>
                                <Descriptions.Item label="agreementFlag">{props.user.agreementFlag}</Descriptions.Item>
                                <Descriptions.Item
                                    label="agreementFlag2">{props.user.agreementFlag2}</Descriptions.Item>
                                <Descriptions.Item
                                    label="customerNumber">{props.user.customerNumber}</Descriptions.Item>
                                <Descriptions.Item label="referral">{props.user.referral}</Descriptions.Item>
                                <Descriptions.Item label="referenceCode">{props.user.referenceCode}</Descriptions.Item>
                                <Descriptions.Item
                                    label="onboardChannel">{props.user.onboardChannel}</Descriptions.Item>
                            </Descriptions>
                        </Panel>
                    </Collapse>
                    <Form
                        form={formUpdateForm}
                        name="control-hooks"
                        onFinish={areYouSureToUpdateUserInfo}
                        onFinishFailed={onFinishFailed}
                    >
                        <Form.Item>
                            <Button
                                block
                                loading={formButtonLoadingVisible}
                                //onClick={}
                                type="primary"
                                disabled
                                htmlType="submit">
                                Güncelle
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
                <TabPane tab={badgeTransactionCount} key="2">
                    <Table
                        dataSource={userTransactionResponse}
                        columns={columnsTransaction}
                        loading={tableLoadingVisible}
                        size="small"
                        expandable={{
                            expandedRowRender: record => (
                                <div>
                                    <p style={{margin: 0}}>Gönderen IBAN: {record.sender_iban}</p>
                                    <p style={{margin: 0}}>Alıcı IBAN: {record.recipient_iban}</p>
                                    <p style={{margin: 0}}>Banka Adı: {record.bank_name}</p>
                                    <p style={{margin: 0}}>Açıklama: {record.description}</p>
                                    <p style={{margin: 0}}>Id: {record.id}</p>

                                    <p style={{margin: 0}}>Bank BIC: {record.bank_bic}</p>
                                    <p style={{margin: 0}}>tanShemaId: {record.tanShemaId}</p>
                                    <p style={{margin: 0}}>bank_financialId: {record.bank_financialId}</p>
                                    <p style={{margin: 0}}>sourceAccountId: {record.sourceAccountId}</p>
                                    <p style={{margin: 0}}>isbankStatus: {record.isbankStatus}</p>
                                    <p style={{margin: 0}}>user_id: {record.user_id}</p>
                                    <p style={{margin: 0}}>username: {record.username}</p>
                                    <p style={{margin: 0}}>payment_ref: {record.payment_ref}</p>
                                    <p style={{margin: 0}}>currency: {record.currency}</p>
                                    <p style={{margin: 0}}>currency: {record.currency}</p>
                                </div>

                            ),
                            rowExpandable: record => record.name !== 'Not Expandable',
                        }}/>
                </TabPane>
                <TabPane tab={badgeTransactionGiftCount} key="3">
                    <div>
                        <div style={{display: "flex"}}>
                            <Form
                                form={formGift}
                                name="control-hooks"
                                onFinish={onFinishgiftForm}
                                onFinishFailed={onFinishFailed}>
                                <Form.Item
                                    name="giftValue"
                                    label="Hediye Değeri">
                                    <InputNumber/>
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        block
                                        loading={formButtonLoadingVisible}
                                        type="primary"
                                        disabled
                                        htmlType="submit">
                                        Hediye Ver
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <Table
                            dataSource={userCommTransactionResponse}
                            columns={colCommTransactions.columns}
                            loading={tableLoadingVisible}
                            size="small"/>
                    </div>
                </TabPane>
                <TabPane tab={badgeLimitAmount} key="4">
                    <Descriptions column={1} bordered={true} size={'small'}>
                        {/* <Descriptions.Item label="Tek İşlem Limiti">{userLimitAmountResponse}</Descriptions.Item>
                        <Descriptions.Item label="Toplam Kullanıcı Limiti">{userTotalLimitAmountResponse}</Descriptions.Item>
                        <Descriptions.Item label="Limit Arttırımı">{userLegitimeResponse}</Descriptions.Item> */}
                        <Descriptions.Item label="Günlük Txn Limiti">{userTxnLimitAmountResponse}</Descriptions.Item>
                        <Descriptions.Item label="Aylık Txn Limiti">{userMonthlyTxnLimitAmountResponse}</Descriptions.Item>
                        <Descriptions.Item label="ISBANK Günlük Limiti">{userIsbankTxnLimitAmountResponse}</Descriptions.Item>
                        <Descriptions.Item label="ISBANK Aylık Limiti">{userIsbankMonthlyTxnLimitAmountResponse}</Descriptions.Item>
                    </Descriptions>

                    <center> <text style={{fontWeight: "bold"}}> Kullanıcının açılacak limitleri</text></center>

                    <Table
                        dataSource={usersLastLimits}
                        columns={colLimit.columns}
                        loading={tableLoadingVisible}
                        size="small"
                        expandable={{
                            expandedRowRender: record => (
                                <div>
                                    <p style={{margin: 0}}>Gönderen IBAN: {record.sender_iban}</p>
                                    <p style={{margin: 0}}>Alıcı IBAN: {record.recipient_iban}</p>
                                    <p style={{margin: 0}}>Banka Adı: {record.bank_name}</p>
                                    <p style={{margin: 0}}>Açıklama: {record.description}</p>
                                    <p style={{margin: 0}}>İşlem tarihi: {record.readableDate}</p>
                                    <p style={{margin: 0}}>Id: {record.id}</p>

                                    <p style={{margin: 0}}>Bank BIC: {record.bank_bic}</p>
                                    <p style={{margin: 0}}>tanShemaId: {record.tanShemaId}</p>
                                    <p style={{margin: 0}}>bank_financialId: {record.bank_financialId}</p>
                                    <p style={{margin: 0}}>sourceAccountId: {record.sourceAccountId}</p>
                                    <p style={{margin: 0}}>isbankStatus: {record.isbankStatus}</p>
                                    <p style={{margin: 0}}>user_id: {record.user_id}</p>
                                    <p style={{margin: 0}}>username: {record.username}</p>
                                    <p style={{margin: 0}}>payment_ref: {record.payment_ref}</p>
                                    <p style={{margin: 0}}>currency: {record.currency}</p>

                                </div>
                            ),
                            rowExpandable: record => record.name !== 'Not Expandable',
                        }}/>
                </TabPane>
                <TabPane tab={badgeOther} key="5">
                    <Form
                        form={formTransferNum}
                        name="control-hooks"
                        onFinish={areYouSureToTransferNumber}
                        onFinishFailed={onFinishFailed}
                        >
                        <Form.Item
                            name="newPhoneNum"
                            label="Yeni Telefon Numarası">
                            <Input/>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                loading={formButtonLoadingVisible}
                                //onClick={}
                                type="primary"
                                htmlType="submit">
                                Numarayı Taşı
                            </Button>
                        </Form.Item>
                    </Form>

                    <Form
                        form={formDeleteUser}
                        name="control-hooks"
                        onFinish={onFinishDisableUserControl}
                        onFinishFailed={onFinishFailed}>
                        <Form.Item>
                            <Button
                                block
                                loading={formButtonLoadingVisible}
                                //onClick={}
                                type="danger"
                                htmlType="submit">
                                Kullanıcıyı Sil
                            </Button>
                        </Form.Item>
                    </Form>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

// getIsbankCustomerInfo()
// TnxLimit
export default UserDetail;
