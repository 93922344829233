import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import { DownloadOutlined, SaveOutlined,ArrowLeftOutlined } from '@ant-design/icons';
import {Button, Divider, Row, Typography, Form, Input, Col, notification as antNotification} from "antd";
import notificationStyles from './campaign.module.css'
import { createOrUpdateNotificationMessages } from "../../utils/notifications";
import {getCampaign, getPinpointCampaignType} from "../../utils/campaign";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Values } from 'redux-form-website-template';
import store from "./store";
import showResults from "./showResults";
import SelectingFormValuesForm from "./SelectingFormValuesForm";
import _ from "lodash";
import moment from "moment";
const rootEl = document.getElementById("root");

const CreateNotification = () => {
    return (
        <>
            <Provider store={store}>
                <div style={{ padding: 15 }}>
                    <SelectingFormValuesForm onSubmit={showResults} />
                </div>
            </Provider>
        </>
    )
}

export default CreateNotification;