import React, { useState, useEffect } from 'react'
import styles from './Summary.module.scss'
import classNames from 'classnames'
import getSummary from '../../utils/getSummary'
import getWithPagination from '../../utils/getWithPagination'
import StylEZ from '../../utils/StylEZ'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import postGift from '../../utils/postGift'
import Modal from '../../components/Modal/Modal'
import getUserDetail from '../../utils/getUserDetail'
import UserDetail from '../../components/UserDetail/UserDetail';
import {Input, Spin} from 'antd';
import StatusTag from '../../components/StatusTag/StatusTag';
import LabelTag from '../../components/LabelTag/LabelTag';
import StatusDescTile from '../../components/StatusDescTile/StatusDescTile';
import CaptionWithHead from '../../components/CaptionWithDate/CaptionWithDate';
import {postUserCognitoInfo, postUserInfoUpdate} from '../../utils/postUserCognitoInfo';
import postTransferPhoneNumber from '../../utils/postTransferPhoneNumber';
import { notification } from 'antd';
import deleteUserInfo from "../../utils/deleteUserInfo";
import postCancelAndTriggerTransaction from "../../utils/postCancelAndTriggerTransaction";
import postCustomerInfo from "../../utils/postCustomerInfo";
import postSendEmail from "../../utils/postSendEmail";
import {Button} from 'antd';

const stylez = new StylEZ(styles)


// 0 = sadece login olmus - confirmed
// 1 = onboard olmus ve waiting - sari
// 2 = onboard basarili = yesil
// 3 = onboard failed = kirmizi

const parseDate = d => {
  const date = new Date(d)
  let dateString = `${date
    .getDate()
    .toString()
    .padStart(2, '0')}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getFullYear()}`
  return dateString
}

// "signupStatus": "aboutYou",
// "onboardStatus": "initial",
// "legitimationStatus": "notExist",
// "pgAccountStatus": "notExist"


const UserRow = ({
  name,
  familyName,
  email,
  emailVerified,
  phoneNumber,
  phoneNumberVerified,
  createdAt,
  updatedAt,
  onboardStatus,
  accountCount,
  sub,
  signupStatus,
  legitimationStatus,
  pgAccountStatus,
  clickedFunc
}) => (
  <tr onClick={() => clickedFunc(sub)} >
    <td>
      <StatusTag status={onboardStatus} />
    </td>
    <td>{name + ' ' + familyName}</td>
    <td>
      <LabelTag
        label={phoneNumber}
        condition={phoneNumberVerified === 'true'}
      />
    </td>
    <td>
      <LabelTag label={email} condition={emailVerified === 'true'} />
    </td>
    <td>{signupStatus}</td>
    <td>{onboardStatus}</td>
    <td>{legitimationStatus}</td>
    <td>{pgAccountStatus}</td>
    <td>{accountCount}</td>
    <td align="center">{parseDate(createdAt)}</td>
    <td align="center">{parseDate(updatedAt)}</td>
  </tr>
)

const Table = ({
  sorter,
  setSorter,
  users,
  paginationToken,
  loading,
  loadUsers,
  clickedFunc
}) => (
  <>
    <div className={classNames(styles['table-container'])}>

      <table
        className={classNames(
          styles['table'],
          styles['is-hoverable'],
          styles['is-fullwidth']
        )}
      >
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Full Name</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>Signup Status</th>
            <th>Onboard Status</th>
            <th>Legitimation Status</th>
            <th>Account Status</th>
            <th>Account Count</th>
            <th
              className={classNames(styles['is-clickable'], {
                [styles['is-sorted-index']]: sorter?.column === 'createdAt'
              })}
            >
              <button
                className={classNames(
                  stylez.button,
                  stylez.buttonIsBold,
                  stylez.isText,
                  {
                    [stylez.isSortedIndex]: sorter?.column === 'createdAt'
                  }
                )}
                onClick={() =>
                  setSorter({
                    column:
                      'createdAt' === sorter.column
                        ? sorter.column
                        : 'createdAt',
                    order:
                      'createdAt' === sorter.column
                        ? sorter.order === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                  })
                }
              >
                Creation Date
              </button>
            </th>
            <th
              className={classNames(styles['is-clickable'], {
                [styles['is-sorted-index']]:
                  sorter?.column === 'updatedAt'
              })}
            >
              <button
                className={classNames(
                  styles['button'],
                  styles['button-is-bold'],
                  styles['is-text'],
                  {
                    [styles['is-sorted-index']]:
                      sorter?.column === 'updatedAt'
                  }
                )}
                onClick={() =>
                  setSorter({
                    column:
                      'updatedAt' === sorter.column
                        ? sorter.column
                        : 'updatedAt',
                    order:
                      'updatedAt' === sorter.column
                        ? sorter.order === 'asc'
                          ? 'desc'
                          : 'asc'
                        : 'asc'
                  })
                }
              >
                Last Modified
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {users.map((u, i) => (
            <UserRow
              key={i}
              onboardStatus={u.onboardStatus}
              name={u.name}
              familyName={u.surname}
              email={u.email}
              emailVerified={u.emailVerified}
              phoneNumber={u.userId}
              phoneNumberVerified={u.phoneNumberVerified}
              createdAt={u.createdAt}
              updatedAt={u.updatedAt}
              sub = {u}
              accountCount = {u.accountCount}
              signupStatus={u.signupStatus}
              legitimationStatus={u.legitimationStatus}
              pgAccountStatus={u.pgAccountStatus}
              clickedFunc={clickedFunc}
            />
          ))}
        </tbody>
      </table>
    </div>
    <button
      disabled={!paginationToken && users.length > 0}
      onClick={() => loadUsers(paginationToken)}
      className={classNames(
        styles['button'],
        styles['is-primary'],
        styles['is-fullwidth'],
        {
          [styles['is-loading']]: loading
        }
      )}
    >
      Load More
    </button>
  </>
)

const Summary = props => {
  const [sorter, setSorter] = useState({
    column: undefined, // 'userCreateDate' : 'userLastModifiedDate'
    order: undefined // 'asc' : 'desc'
  })
  const [loading, setLoading] = useState(false)
  const [paginationToken, setPaginationToken] = useState(undefined)
  const [users, setUsers] = useState([])
  const [summary, setSummary] = useState({})
  const [transactionSummary, setTransactionSummary] = useState({})
  const [searchedUser, setSearchedUser] = useState('49')
  const [searchedNameUser, setSearchedNameUser] = useState('')
  const [searchedSurnameUser, setSearchedSurnameUser] = useState('')
  const [timeframe, setTimeframe] = useState(undefined)

  const [visibleMessageModal, setVisibleMessageModal] = useState(false)

  const toggleMessageModal = (type) => {
    setVisibleMessageModal(!type)
  }

  const [visibleUserDetail, setVisibleUserDetail] = useState(false)

  const closeUserDetail = () => {setVisibleUserDetail(false) }
  const openUserDetail = () => {setVisibleUserDetail(true) }

  const [giftResponse, setGiftResponse] = useState(undefined)
  const [userDetailResponse, setUserDetailResponse] = useState({name:""});
  const [userTransactionCount, setUserTransactionCount] = useState(0);
  const [userCommTransactionCount, setUserCommTransactionCount] = useState(0);
  const [userCustomerSegmentType, setUserCustomerSegmentType] = useState(0);


    const [spinloading, setSpinLoading] = useState(false);

  const loadUsers = (pt, user, time, type) => {
    setLoading(true)
    getWithPagination(pt, user, time, type)
      .then(response => {
        setPaginationToken(response.nextToken)
        if (pt) concatUsers(users, response.userList)
        if (!pt) concatUsers([], response.userList)
        setLoading(false)

      })
      .catch(error => {
        //console.log(error)
        setLoading(false)
      })
  }

  const concatUsers = (currentUsers, newUsers) => {
    const preSort = [...currentUsers, ...newUsers.filter(u => u.name)]
    const sorted = [...preSort].sort((a, b) => {
      if (sorter.column === 'userCreateDate')
      {
        return (
          (Date.parse(b.userCreateDate) - Date.parse(a.userCreateDate)) *
          (sorter.order === 'asc' ? -1 : 1)
        )
      } else if (sorter.column === 'userLastModifiedDate') {
        return (
          (Date.parse(b.userLastModifiedDate) -
            Date.parse(a.userLastModifiedDate)) *
          (sorter.order === 'asc' ? -1 : 1)
        )
      } else {
        return 0
      }
    })
    setUsers(sorted)
  }

  const giveGifttoUser = async (phoneNumber,name,familyName,giftValue) => {
    setSpinLoading(true);
    const transformedPhoneNumber = phoneNumber.slice(1,phoneNumber.length);
    postGift(transformedPhoneNumber,giftValue)
    .then(response => {
      setGiftResponse(undefined);
     // toggleMessageModal(false);

      let title = "";
      let content = "";
      if(response.code === 0)
      {
        title = "Warning";
        setGiftResponse("Only one gift can be given on the same day.");
        content = "Only one gift can be given on the same day.";

      }
      else{
        title = "Success!";
        setGiftResponse(name + " " + familyName +", "+ giftValue + " euro gift phone number: " + transformedPhoneNumber);
        content = name + " " + familyName +", "+ giftValue + " euro gift phone number: " + transformedPhoneNumber;
      }

      openNotification(title,content,response.code);
      setSpinLoading(false);
    }).catch(error => {
        alert(error);
        setSpinLoading(false);
      })
  }


  const updateUserCognitoInfo = async (sub,cognitoGroup,alternativeNames) => {
    setSpinLoading(true);
    postUserCognitoInfo(sub,cognitoGroup,alternativeNames)
    .then(response => {
      let title = "";
      let content = "";
      if(response.code === 0)
      {
        title = "Başarılı!";
        content = "Operations tablosuna başarıyla kaydedildi.";


      } else if(response.code === 1) {
          title = "Başarılı!";
          content = "İşlem başarıyla gerçekleştirildi.";

      }
      else if(response.code === 2){
        title = "Hata!";
        content = "Error during update "+ response.cognitoResult.message;
      }

      openNotification(title,content,response.code);
      setSpinLoading(false);
    })

  }
    const updateUserInfo = async (sub,id,name,surname,email,birthDate,nationality,isbankCustomerNo,street,birthCountry,issuePlace,streetNr,issueCountry,zipCode,city,birthPlace,onboardStatus) => {
        setSpinLoading(true);
        postUserInfoUpdate(sub,id,name,surname,email,birthDate,nationality,isbankCustomerNo,street,birthCountry,issuePlace,streetNr,issueCountry,zipCode,city,birthPlace,onboardStatus)
            .then(response => {
                let title = "";
                let content = "";
                console.log("response asdasd",response)
                if(response.code === 0)
                {
                    title = "Başarılı!";
                    content = "Operations tablosuna başarıyla kaydedildi.";


                } else if(response.code === 1) {
                    title = "Başarılı!";
                    content = "İşlem başarıyla gerçekleştirildi.";

                }
                else if(response.code === 2){
                    title = "Hata!";
                    content = "Error during update "+ response.cognitoResult.message;
                }

                openNotification(title,content,response.code);
                setSpinLoading(false);
            })

    }


  const tranferUserPhoneNumber = async (sub,oldPhoneNumber,newPhoneNumber) => {

    setSpinLoading(true);
    postTransferPhoneNumber(sub,oldPhoneNumber,newPhoneNumber)
    .then(response => {

      let title = "";
      let content = "";
      console.log("response = "+ response)

        // if(response.code === 0)
        // {
        //     title = "Başarılı!";
        //     content = "Operations tablosuna başarıyla kaydedildi.";


        // } else if(response.code === 1) {
            title = "Başarılı!";
            content = "İşlem başarıyla gerçekleştirildi.";
            response.code = 1;

        // }
        // else if(response.code === 2){
        //     title = "Hata!";
        //     content = "Error during update "+ response.cognitoResult.message;
        // }

      openNotification(title,content,response.code);
      setSpinLoading(false);
    }).catch(error => {
      console.log(error);
      alert(error.response.data.errorMessage);
      setSpinLoading(false);
  });

  }



  const deleteCognitoAndTableInfo = async (sub) =>{
      setSpinLoading(true);
      deleteUserInfo(sub)
          .then(response => {
              let title = "";
              let content = "";
              // if(response.code === 0)
              // {
              //     title = "Başarılı!";
              //     content = "Operations tablosuna başarıyla kaydedildi.";


              // } else if(response.code === 1) {
                  title = "Başarılı!";
                  content = "İşlem başarıyla gerçekleştirildi.";
                  response.code = 1;

              // }
              // else if(response.code === 2){
              //     title = "Hata!";
              //     content = "Error during update "+ response.cognitoResult.message;
              // }
              openNotification(title,content,response.code);
              setSpinLoading(false);

          }).catch(error => {
            console.log(error);
            alert(error.response.data.errorMessage);
            setSpinLoading(false);
        });
    }
    const segmentTypeChange = async (sub,segmentType,segmentRefCode) =>{
        setSpinLoading(true);
        postCustomerInfo(sub,segmentType,segmentRefCode)
            .then(response => {

                let title = "";
                let content = "";
                if(response.code === 0)
                {
                    title = "Başarılı!";
                    content = "Operations tablosuna başarıyla kaydedildi.";


                } else if(response.code === 1) {
                    title = "Başarılı!";
                    content = "İşlem başarıyla gerçekleştirildi.";

                }
                else if(response.code === 2){
                    title = "Hata!";
                    content = "Error during update "+ response.cognitoResult.message;
                }
                openNotification(title,content,response.code);
                setSpinLoading(false);

            });
    }

    const cancelAndTriggerTransaction = async (sub,command,paymentRef) =>{
        setSpinLoading(true);
        postCancelAndTriggerTransaction(sub,command,paymentRef)
            .then(response => {

                let title = "";
                let content = "";
                if(response.code === 0)
                {
                    title = "Başarılı!";
                    content = "Operations tablosuna başarıyla kaydedildi.";


                } else if(response.code === 1) {
                    title = "Başarılı!";
                    content = "İşlem başarıyla gerçekleştirildi.";

                }
                else if(response.code === 2){
                    title = "Hata!";
                    content = "Error during update "+ response.cognitoResult.message;
                }
                openNotification(title,content,response.code);
                setSpinLoading(false);

            });
    }

    const sendEmailCognitoUserInfo = async (sub,isbankCusNum,name,surname,email) => {
        setSpinLoading(true);
        postSendEmail(sub,isbankCusNum,name,surname,email)
            .then(response => {
                let title = "";
                let content = "";
                if(response.code === 0)
                {
                    title = "Başarılı!";
                    content = "Operations tablosuna başarıyla kaydedildi.";



                } else if(response.code === 1) {
                    title = "Başarılı!";
                    content = "İşlem başarıyla gerçekleştirildi.";

                }
                else if(response.code === 4){
                    title = "Hata!";
                    content = "Error during update "+ response.cognitoResult.message;
                }

                openNotification(title,content,response.code);
                setSpinLoading(false);
            })

    }


  const userClickedHandler = (sub) => {
    //alert('userClickedHandler');
    setSpinLoading(true);

    console.log(sub);
    getUserDetailCall(sub)

  }

  const getUserDetailCall = async (sub) => {
    // getUserDetail(sub)
    // .then(response => {
      setUserDetailResponse(sub);
    //   setUserTransactionCount(response.userTransactionCount);
    //   setUserCommTransactionCount(response.userCommissionTransactionCount);
    //   setUserCustomerSegmentType(response.customerUserInfo);



      openUserDetail();
      setSpinLoading(false);
    // }).catch(error => {
    //   alert(error);
    // });
  }

  const openNotification = (title, content,code) => {
    if(code === 0)
    {
      notification.error({
        message: title,
        description: content,
        onClick: () => {
          //console.log('Notification Clicked!');
        }
      });
    }
    else{
      notification.success({
        message: title,
        description: content,
        onClick: () => {
          //console.log('Notification Clicked!');
        }
      });
    }
  };


  const filterUsers = async values => {

    
      if(searchedUser.length > 5)
      {
        console.log("search with phone: " + searchedUser)
        loadUsers(undefined, searchedUser, timeframe, "p")
      }
      else {
        if(searchedNameUser.length > 3 || searchedSurnameUser.length > 3)
        {
          console.log("search with name: " + searchedNameUser + "~" + searchedSurnameUser)
          loadUsers(undefined, searchedNameUser + "~" + searchedSurnameUser, timeframe, "n")
        }
      }

      if(searchedUser.length < 4 && searchedNameUser.length == 0 && searchedSurnameUser.length == 0)
      {
        loadUsers(undefined);
      }
  };

  useEffect(() => {

    const sorted = [...users].sort((a, b) => {
      if (sorter.column === 'userCreateDate') {
        return (
          (Date.parse(b.userCreateDate) - Date.parse(a.userCreateDate)) *
          (sorter.order === 'asc' ? -1 : 1)
        )
      } else if (sorter.column === 'userLastModifiedDate') {
        return (
          (Date.parse(b.userLastModifiedDate) -
            Date.parse(a.userLastModifiedDate)) *
          (sorter.order === 'asc' ? -1 : 1)
        )
      } else {
        return 0
      }
    })
    setUsers(sorted)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorter])

  useEffect(() => {

    getSummary()
      .then(response => {
        setSummary(response)

      })
      .catch(error => {
        //console.log(error.response)



      })

    loadUsers(paginationToken)


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal show={visibleMessageModal} handleClose={() => toggleMessageModal(visibleMessageModal)} result={giftResponse}>

      </Modal>

      <CaptionWithHead summary={summary?.date} />

     <StatusDescTile summary={summary}/>

     <Spin spinning={spinloading}>
      <UserDetail
        visible={visibleUserDetail}
        onCancel={() => { closeUserDetail(false); }}
        user={userDetailResponse}
        transactionCount={userTransactionCount}
        commTransactionCount={userCommTransactionCount}
        customerSegmentTypeCount ={userCustomerSegmentType}
        onGiveGiftClicked={ giveGifttoUser}
        onUpdateUserCognitoInfoClicked = {updateUserCognitoInfo}
        onTransferNumberClicked = {tranferUserPhoneNumber}
        onUpdateUserInfoClicked = {updateUserInfo}
        onSegmentTypeChange = {segmentTypeChange}
        sendEmailUserInfo ={sendEmailCognitoUserInfo}
        onCancelAndTriggerTransaction = {cancelAndTriggerTransaction}
        deleteCognitoUser = {deleteCognitoAndTableInfo}/>

        </Spin>

      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "30px", marginTop: "30px"}}>
        <div  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
          <span><PhoneInput className={classNames(
            styles['tile'])}
            style={{height: "35px", width: "300px", marginRight: "10px"}}
            country='de'
            value={searchedUser}
            regions={'europe'}
            onChange={phone => {
              // var username = phone.replace(/\s/g,'')
              // if (username.length > 5) {

              //   loadUsers(undefined, username, timeframe, "p")
              // }
              // else {
              //   loadUsers(undefined)
              // }
              setSearchedUser(phone)
              setSearchedNameUser('')
              setSearchedSurnameUser('')
            }}
          />
          </span>
          <span style={{marginRight: "10px", marginLeft: "10px"}}>OR</span>
            <span><Input       placeholder="İsim" style={{width: "300px", height:"35px", marginLeft: "10px", borderRadius: "5px"}}
                              size="large"
                              value={searchedNameUser}
                              onChange={name => {
                                  var username = name.target.value;
                                  // if (username.length > 4) {
                                  //     console.log("name.value" + name.target.value)
                                  //     loadUsers(undefined, username, timeframe, "n")
                                  // }
                                  // else {
                                  //     loadUsers(undefined)
                                  // }

                                  setSearchedUser('49')
                                  setSearchedNameUser(username)
                              }}
            /></span>
            <span><Input       placeholder="Soyisim" style={{width: "300px", height:"35px", marginRight: "10px", marginLeft: "10px", borderRadius: "5px"}}
                              size="large"
                              value={searchedSurnameUser}
                              onChange={name => {
                                  var username = name.target.value;
                                  // if (username.length > 4) {
                                  //     console.log("name.value" + name.target.value)
                                  //     loadUsers(undefined, username, timeframe, "n")
                                  // }
                                  // else {
                                  //     loadUsers(undefined)
                                  // }
                                  setSearchedUser('49')
                                  setSearchedSurnameUser(username)
                              }}
            /></span>
        </div>
        
          <span>
            <Button
                block
                style={{width: "300px", height: "35px", borderRadius: "5px"}}
                type="primary"
                onClick={filterUsers}
                htmlType="submit">
                Filter
            </Button>
          </span>
      </div>

      <div className={classNames(styles['box'])}>

          <Spin spinning={spinloading}>
          <Table
          users={users ?? []}
          sorter={sorter}
          setSorter={x => setSorter(x)}
          loadUsers={pt => loadUsers(pt)}
          loading={loading}
          paginationToken={paginationToken}
          clickedFunc={userClickedHandler}
        />
          </Spin>
          </div>
    </>
  )
}

export default Summary
