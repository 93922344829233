import React, {useEffect, useState} from 'react';
import {Table, Divider, Space, Input, Button, Row, Select, Form, InputNumber} from "antd";
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import _ from 'lodash'

import './campaign.module.css'
import {getCampaign, getPinpointCampaignType} from "../../utils/campaign";
import {Link} from "react-router-dom";
import moment from "moment";

const Notifications = (props) => {

    const [stage, setStage] = React.useState(localStorage.getItem('stage') || "dev")
    const [loading, setLoading] = React.useState(false);

    const [notificationMessages, setNotificationMessages] = useState([]);
    const [campaignTable, setCampaignTable] = useState([]);
    const [campaignTypeTable, setCampaignTypeTable] = useState([]);
    const [campaignTypeList, setCampaignTypeList] = useState([]);


    const [searchText, setSearchText] = useState(null);

    const onSearch = e => {
        setSearchText(e.target.value);
    }

    const columns = [

        {
            title: 'Durum',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            render(text, record) {
                return {
                    props: {
                        style: { background: text == "A" ? "green" : "red", color: "white"}
                    },
                    children:
                        (() => {
                            if (text == "A") {
                                if(record.epochStartDate >= Date.now()) {
                                    return (
                                        <div> Henüz Başlamadı </div>
                                    )
                                }else if(record.epochEndDate <= Date.now()) {
                                    return (
                                        <div> Tamamlandı </div>
                                    )
                                }else {
                                    return (
                                        <div> Aktif </div>

                                    )
                                }

                            } if (text == "P") {
                                return (
                                    <div> Pasif </div>
                                )
                            }
                        })()

                };
            }
        },
        {
            title: 'Kampanya Tipi ID',
            dataIndex: 'campaignTypeId',
            key: 'campaignTypeId',
            width: 200,
        },
        {
            title: 'Kampanya Tipi Açıklama',
            dataIndex: 'campaignTypeId',
            key: 'campaignTypeId',
            width: 200,
            render(text, record) {
                return {
                    children:

                        (() => {
                            var filteredCampaignType =  campaignTypeList.filter(function(hero) {
                                return hero.id == text;
                            });
                            console.log('filteredCampaignType',filteredCampaignType)
                            return (
                                <div> {filteredCampaignType[0].description} </div>
                            )

                        })()

                };
            }
        },
        {
            title: 'İşlemi Yapan',
            dataIndex: 'campaignTypeId',
            key: 'campaignTypeId',
            width: 200,
            render(text, record) {
                return {
                    children:

                        (() => {
                            var filteredCampaignType =  campaignTypeList.filter(function(hero) {
                                return hero.id == text;
                            });
                            return (
                                <div> {filteredCampaignType[0].earningUser == "user" ? "Kullanıcı" : "Referans"} </div>
                            )

                        })()

                };
            }
        },
        {
            title: 'Kampanya Gerçekleşme Değeri',
            dataIndex: 'campaignTypeId',
            key: 'campaignTypeId',
            width: 200,
            render(text, record) {
                return {
                    children:

                        (() => {
                            var filteredCampaignType =  campaignTypeList.filter(function(hero) {
                                return hero.id == text;
                            });
                            return (
                                <div> {filteredCampaignType[0].event == "transaction" ? "İşlem" : "Kayıt"}</div>
                            )

                        })()

                };
            }
        },
        {
            title: 'Kampanya Tipi ',
            dataIndex: 'campaignTypeId',
            key: 'campaignTypeId',
            width: 200,
            render(text, record) {
                return {
                    children:

                        (() => {
                            var filteredCampaignType =  campaignTypeList.filter(function(hero) {
                                return hero.id == text;
                            });
                            return (
                                <div> {filteredCampaignType[0].type == "conditional" ? "Şartlı" : "" }</div>
                            )

                        })()

                };
            }
        },
        {
            title: 'Kampanya ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
        },
        {
            title: 'Kampanya Adı',
            dataIndex: 'name',
            key: 'name',
            width: 200,
        },
        {
            title: 'Ne Zaman Başlasın ?',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 200,
        },
        {
            title: 'Ne Zaman Bitsin ?',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 200,
        },
        {
            title: 'Kampanya Değer Hedefi',
            dataIndex: 'triggerType',
            key: 'triggerType',
            width: 200,
            render(text, record) {
                return {
                    children:
                    (() => {
                        if (text == "amount") {
                            return (
                                <div> Tutar </div>
                            )
                        } if (text == "commissionAmount") {
                            return (
                                <div> Komisyon hedefi </div>
                            )
                        } if (text == "count") {
                            return (
                                <div> İşlem Sayısı </div>
                            )
                        }
                    })()

                };
            }
        },
        {
            title: 'Kampanya Değer Hedefi Miktarı',
            dataIndex: 'triggerMetric',
            key: 'triggerMetric',
            width: 200,
        },
        {
            title: 'Kazanımı Ne Olsun?',
            dataIndex: 'earningType',
            key: 'earningType',
            width: 200,
        },
        {
            title: 'Kazanımı Nasıl Hesaplansın?',
            dataIndex: 'earningSource',
            key: 'earningSource',
            width: 200,
            render(text, record) {
                return {
                    children:
                        (() => {
                            if (text == "amount") {
                                return (
                                    <div> İşlem Tutarı Üzerinden </div>
                                )
                            } if (text == "commission") {
                                return (
                                    <div> Komisyon Ücreti Üzerinden </div>
                                )
                            } if (text == "static") {
                                return (
                                    <div> Sabit Tutar </div>
                                )
                            }
                        })()

                };
            }
        },
        {
            title: 'Kazanım Miktarı',
            dataIndex: 'earningMetric',
            key: 'earningMetric',
            width: 200,
        },
        {
            title: 'Kampanyadan bir seferde kaç kere faydalanabilsin?',
            dataIndex: 'usageCount',
            key: 'usageCount',
            width: 200,
        },
        {
            title: 'Hangi Zaman Skalası ile Kullansın?',
            dataIndex: 'usageDateType',
            key: 'usageDateType',
            width: 200,
        },
        {
            title: 'Kullanım Başlangıç Zamanı ?',
            dataIndex: 'usageStartDate',
            key: 'usageStartDate',
            width: 200,
        },
        {
            title: 'Kullanım Bitiş Zamanı ?',
            dataIndex: 'usageEndDate',
            key: 'usageEndDate',
            width: 200,
        },
        {
            title: 'Kampanya Kazanım Kullanım Önceliği (0-10)?',
            dataIndex: 'usagePriority',
            key: 'usagePriority',
            width: 200,
        },
        {
            title: 'Kazanım Anında Bildirim Atılsın mı?',
            dataIndex: 'notifEarnTime',
            key: 'notifEarnTime',
            width: 200,
            render(text, record) {
                return {
                    children:
                        (() => {
                            if (text == "true") {
                                return (
                                    <div> Evet </div>
                                )
                            } if (text == "false") {
                                return (
                                    <div> Hayır </div>
                                )
                            }
                        })()

                };
            }
        },
        {
            title: 'Bildirim ID',
            dataIndex: 'notifEarnId',
            key: 'notifEarnId',
            width: 200,
        },

        {
            title: 'Kazanım Anında Mail Atılsın mı?',
            dataIndex: 'mailEarnTime',
            key: 'mailEarnTime',
            width: 200,
            render(text, record) {
                return {
                    children:
                        (() => {
                            if (text == "true") {
                                return (
                                    <div> Evet </div>
                                )
                            } if (text == "false") {
                                return (
                                    <div> Hayır </div>
                                )
                            }
                        })()

                };
            }
        },

        {
            title: 'Mail Template ID',
            dataIndex: 'mailEarnTemplateId',
            key: 'mailEarnTemplateId',
            width: 200,
        },

        {
            title: 'Kullanım Başlangıç Tarihinde Bildirim Atılsın mı?',
            dataIndex: 'notifUsageTime',
            key: 'notifUsageTime',
            width: 200,
            render(text, record) {
                return {
                    children:
                        (() => {
                            if (text == "true") {
                                return (
                                    <div> Evet </div>
                                )
                            } if (text == "false") {
                                return (
                                    <div> Hayır </div>
                                )
                            }
                        })()

                };
            }
        },
        {
            title: 'Bildirim ID',
            dataIndex: 'notifUsageId',
            key: 'notifUsageId',
            width: 200,
        },
        {
            title: 'Kullanım Anında Mail Atılsın mı?',
            dataIndex: 'mailUsageTime',
            key: 'mailUsageTime',
            width: 200,
            render(text, record) {
                return {
                    children:
                        (() => {
                            if (text == "true") {
                                return (
                                    <div> Evet </div>
                                )
                            } if (text == "false") {
                                return (
                                    <div> Hayır </div>
                                )
                            }
                        })()

                };
            }
        },
        {
            title: 'Mail Template ID',
            dataIndex: 'mailUsageTemplateId',
            key: 'mailUsageTemplateId',
            width: 200,
        },

        {
            title: 'Detay',
            key: 'action',
            width: 100,
            fixed: 'right',
            render: (record) => {
                return (
                    <Space size="middle">
                        <Link to={`/campaign/${record.id}/edit`}>Düzenle</Link>
                    </Space>
                );
            }
        },
    ]

    useEffect(() => {
        getCampaignType()
    }, [stage])


    const getAndMergeNotifications = (stage) => {
        setLoading(true)
        getCampaign(stage).then(response => {
            const groupedNotifications = _.groupBy(response, 'id');
            const campaignTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.language !== '')
                if (turkishNotification) {
                    let showedCampaign = {
                        key: id,
                        id: id,
                        name: turkishNotification.name,
                        earningSource: turkishNotification.earningSource,
                        earningType: turkishNotification.earningType,
                        startDate: moment(turkishNotification.startDate).format("DD-MM-YYYY"),
                        endDate: moment(turkishNotification.endDate).format("DD-MM-YYYY"),
                        campaignTypeId: turkishNotification.campaignTypeId,
                        earningMetric: turkishNotification.earningMetric,
                        triggerMetric: turkishNotification.triggerMetric,
                        triggerType: turkishNotification.triggerType,
                        usageCount: turkishNotification.usageCount,
                        usageDateType: turkishNotification.usageDateType,
                        usageStartDate: moment(turkishNotification.usageStartDate).format("DD-MM-YYYY"),
                        usageEndDate: moment(turkishNotification.usageEndDate).format("DD-MM-YYYY"),
                        usagePriority: turkishNotification.usagePriority,
                        notifEarnTime: turkishNotification.notifEarnTime.toString(),
                        notifEarnId: turkishNotification.notifEarnId,
                        mailEarnTime: turkishNotification.mailEarnTime.toString(),
                        mailEarnTemplateId: turkishNotification.mailEarnTemplateId,
                        notifUsageTime: turkishNotification.notifUsageTime.toString(),
                        notifUsageId: turkishNotification.notifUsageId,
                        mailUsageTime: turkishNotification.mailUsageTime.toString(),
                        mailUsageTemplateId: turkishNotification.mailUsageTemplateId,
                        status: turkishNotification.status,
                        epochStartDate : turkishNotification.startDate,
                        epochEndDate : turkishNotification.endDate,
                    }
                    campaignTable.push(showedCampaign)
                }
            });
            setNotificationMessages(response);
            localStorage.setItem('campaignList', JSON.stringify(response));
            localStorage.setItem('stage', stage);

            console.log('campaignTable', campaignTable);

            setCampaignTable(campaignTable)
            setLoading(false)
        })
    }
    const getCampaignType = () => {
        setLoading(true)
        getPinpointCampaignType(stage).then(response => {
            getAndMergeNotifications(stage)
            const groupedNotifications = _.groupBy(response, 'id');
            const localCampaignTable = [];
            Object.keys(groupedNotifications).map(id => {
                const turkishNotification = groupedNotifications[id].find(item => item.event !== '')
                if (turkishNotification) {
                    let showedCampaign = {
                        key: id,
                        id: id,
                        description: turkishNotification.description,
                        earningUser: turkishNotification.earningUser,
                        event: turkishNotification.event,
                        type: turkishNotification.type,
                    }
                    localCampaignTable.push(showedCampaign)
                }
            });
            setCampaignTypeList(response);
            localStorage.setItem('getPinpointCampaignType', JSON.stringify(response));
            setCampaignTypeTable(campaignTable)
            console.log('campaignTypeTable', localCampaignTable);
            setLoading(false)
        })
    }

    return (
        <>
            <Divider>Campaign</Divider>
            <Row justify="space-between" align="middle" style={{marginBottom: '1rem'}}>
                <Select defaultValue={stage} style={{width: 120}} onChange={(val) => setStage(val)}>
                    <Select.Option value="dev">Test</Select.Option>
                    <Select.Option value="prod">Prod</Select.Option>
                </Select>
                <Link to={`/campaign/create`}>
                    <Button type="primary">Yeni Kampanya Oluştur</Button>
                </Link>
            </Row>
            <Input.Search
                placeholder="Search with id"
                allowClear
                size="large"
                enterButton
                onChange={e => onSearch(e)}
            />
            <Table scroll={{x: 1200}} auto loading={loading}
                   dataSource={campaignTable.sort(
                       function(a, b) {
                               return b.epochStartDate - a.epochStartDate
                       })}
                   columns={columns}/>
        </>
    );
}

export default Notifications;