const colCommTransactions = {
    columns:[
      {
        title: 'Oluşturulma Tarihi',
        dataIndex: 'newDate',
        key: 'newDate',
        sorter: (a, b) => a.CreationTime - b.CreationTime,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Tutar',
        dataIndex: 'Amount',
        key: 'Amount',
        sorter: (a, b) => a.Amount - b.Amount,
        sortDirections: ['descend', 'ascend']
      },
      {
          title:"İşlem Id",
          dataIndex:"TransactionId",
          key:"TransactionId"
      },
      
    ]
  }

  export default colCommTransactions;