/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import classNames from 'classnames'
import { Link } from "react-router-dom";
import styles from './Navbar.module.scss'
import signOut from '../../utils/signOut'

const Navbar = props => {
  const [menu, toggleMenu] = useState(false)
  return (
    <nav
      className={classNames(
        styles.navbar,
        styles['is-white']
        // styles['is-hidden-tablet']
      )}
    >
      <div className={classNames(styles.container)}>
        <div className={classNames(styles['navbar-brand'])}>
          <Link
            to="/"
            className={classNames(styles['navbar-item'], styles['brand-text'])}
          >
            ParaGönder
          </Link>
          <div
            className={classNames(styles['navbar-burger'], styles.burger)}
            data-target="navMenu"
            onClick={() => toggleMenu(!menu)}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={classNames(styles['navbar-menu'], {
            [styles['is-active']]: menu
          })}
        >
          {/* <Link
              className={classNames(styles['navbar-item'])}
              to="/notifications"
          >
            Notifications
          </Link>
          <Link
              className={classNames(styles['navbar-item'])}
              to="/operations"
          >
            Approvals
          </Link>
          <Link
              className={classNames(styles['navbar-item'])}
              to="/campaign"
          >
            Campaign
          </Link> */}
          <div className={classNames(styles['navbar-start'])}>
            <a
              className={classNames(styles['navbar-item'])}
              href="#"
              onClick={signOut}
            >
              Sign Out
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
