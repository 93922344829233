import { API, Auth } from 'aws-amplify'

const getUserCommTransactions = async (sub) => {

    return API.get('newDashboard', '/discount/' + sub, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })   
  }
  
  export default getUserCommTransactions;
  