import React from 'react';
import styles from '../../routes/summary/Summary.module.scss';
import classNames from 'classnames'


const StatusDescTile = (props) => (

<div className={classNames(styles[''])}>
<div
  className={classNames(
    styles['status-info-columns'],
    styles['tile'],
    styles['is-ancestor']
  )}
>
  <div
    className={classNames(
      styles['tile'],
      styles['is-parent'],
      styles['is-one-quarter']
    )}
  >
    <span
      className={classNames(
        styles['box'],
        styles['info-box'],
        styles['is-fullwidth'],
        styles['has-background-info'],
        styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-7']
      )}
    >
      Sadece Onboard Olmus
      <br/>
      <h4 
        className ={
        classNames(
          styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-4'])
      }>{props.summary?.total}</h4>
    </span>
  </div>
  <div
    className={classNames(
      styles['tile'],
      styles['is-parent'],
      styles['is-one-quarter']
    )}
  >
    <span
      className={classNames(
        styles['box'],
        styles['info-box'],
        styles['is-fullwidth'],
        styles['has-background-warning'],
        styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-7']
      )}
    >
      Onboard olmus ama onay bekleyen
      <br/>
      <h4 
        className ={
        classNames(
          styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-4'])
      }>{props.summary?.waiting}</h4>
    </span>
  </div>
  <div
    className={classNames(
      styles['tile'],
      styles['is-parent'],
      styles['is-one-quarter']
    )}
  >
    <span
      className={classNames(
        styles['box'],
        styles['info-box'],
        styles['is-fullwidth'],
        styles['has-background-success'],
        styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-7']
      )}
    >
      Onboard olmus ve kaydi onaylanan
      <br/>
      <h4 
        className ={
        classNames(
          styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-4'])
      }>{props.summary?.onboardNotStarted}</h4>
    </span>
  </div>
  <div
    className={classNames(
      styles['tile'],
      styles['is-parent'],
      styles['is-one-quarter']
    )}
  >
    <span
      className={classNames(
        styles['box'],
        styles['info-box'],
        styles['is-fullwidth'],
        styles['has-background-danger'],
        styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-7']
      )}
    >
      Basvurusu reddedilen
      <br/>
      <h4 
        className ={
        classNames(
          styles['has-text-white'],
        styles['is-shadowless'],
        styles['is-size-4'])
      }>{props.summary?.rejected}</h4>
    </span>
  </div>
</div>
</div>

)

export default StatusDescTile;