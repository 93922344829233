import { API, Auth } from 'aws-amplify'

const getUserDetail = async (sub) => {

    return API.get('dashboard', '/dashboard/users/' + sub, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      }
    })   
  }
  
  export default getUserDetail
  