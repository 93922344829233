import { API, Auth } from 'aws-amplify'

const getSummary = async () => {
  return API.get('newDashboard', '/customer/summary', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
      //'Access-Control-Allow-Origin': '*'
    } // OPTIONAL (return the entire Axios response object instead of only response.data)
    // queryStringParameters: {
    //   // OPTIONAL
    //   name: 'param'
    // }
  })
}

export default getSummary